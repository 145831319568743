/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import './index.css';
import './assets/scss/styles.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'flag-icon-css/css/flag-icons.min.css';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr', 'de', 'es'],
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'path', 'cookie', 'htmlTag', 'subdomain'],
      caches: ['localStorage'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

const root = createRoot(document.getElementById('root'));

const element = (
  <Suspense>
    <App />
  </Suspense>
);

root.render(element);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
