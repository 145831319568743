const seriesDb = {
  Series: [
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Sound Code and the Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ede7c595-1d24-4451-b961-d3bf4335717e/sound_code_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'v2IDv2r43Ns',
          Messages: ['Sf3eqBq1kvc', 'No80k8u5nZQ', '4f8MjLdItCE'],
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['jMDhdI2po9s', 'LjlX7UJx6yY', 'Lrob9QcgZoU'],
          SeasonId: 'pmmxy5vFNs8',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'iWVZrRTKy3o',
      Description:
        'God spoke creation into being. Why did he speak? What’s so important about words? By speaking,  we produce sound; when the right sound code is applied you call forth things from the spiritual realm into the physical world. In this insightful masterpiece by Pastor Chris,  discover the principle of creation and recreation. Tap into the wealth of divine principles and live in the future of your dreams as you learn the workable principle of Sound code and the Spirit.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ede7c595-1d24-4451-b961-d3bf4335717e/sound_code_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o'],
      Title: '2013 - The Year of Favour',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4af81ebd-5c14-420a-bc2b-05c3d1bfcf0e/the_year_of_favour_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'GeAAhxvpkRA',
          Messages: ['m6vpEfpg9Fs'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'K6fWrzet7BE',
      Status: 'Published',
      Description:
        "Discover how to tap into the grace of God for your life and how to enjoy God's endless favour continually.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4af81ebd-5c14-420a-bc2b-05c3d1bfcf0e/the_year_of_favour_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak', 'RXY0Q2L91_o'],
      Title: '7 Gifts of God in Christ Jesus',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/db150cca-dc71-4f8e-9d46-6d6f55da2297/7-Gifts-of-God-in-Christ.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'tdPNwFSxkcU',
          Messages: ['ZvxAOX8xdr4', 'tmUoQ1GKQgU'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['PFlkjQjHXTo'],
          SeasonId: 'jbPYIAIaIEs',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'sxVOnx_6Yqg',
      Description:
        'The gifts of God are freely given, but for us to have full access to them we must understand and accept what has been delivered to us in Christ. In this special teaching by Pastor Chris, be awakened to the knowledge of the 7 Gifts of God in Christ Jesus.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/db150cca-dc71-4f8e-9d46-6d6f55da2297/7-Gifts-of-God-in-Christ.png',
    },
    {
      Categories: ['yB4dKm3LNec'],
      Title: 'Your LoveWorld Specials',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/your_loveworld_specials.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Season 2',
          Messages: [
            'akKyf7cCRVU',
            '3G3gq1fzMl0',
            '8cX4W39e-2M',
            'YlSvIhwHra8',
            'FBIUBgxr9qI',
            'ccV5qOzvpUM',
            '0zM0w2m3DJk',
            'RGuw7uy0mlM',
            '7U4cryoxMAU',
            '7Gl4McWb_SE',
            'Pr0BEzCQbY8',
            'SlYPphxHua8',
            'xb_ubYhbWhw',
            'IoY_gTo-XjU',
            'uESFLPVsJug',
            'v_5PHx04w3U',
            'J3s6r7pcLys',
            'P5TjR19X5mg',
            'KS0VuJNNWHI',
            'j1VSGnTETfk',
            'jCuxHXO7TAA',
            'FTyvjOlSbvw',
            'ZJAB86mFgBU',
            'J1QMgpz7olM',
            'joJbdoSuCYI',
            '-taO8qWt6nk',
            'E07oLt_Mrow',
            'i0oJ6bLtqLU',
            'C4yH1SfDx6U',
            'GO-mAxB5Nhw',
            '61pMILTUPDE',
            'X8TeMgNFf80',
            'yZC3AokpGng',
            '6LnBVnK3l3o',
            'x76fBOsZsJc',
            'IwQRBEPdtzs',
            'f7zzzs1hu18',
            'Z3PPxJm75Ag',
            'L1Kkm8jzcR0',
          ],
          SeasonId: 'BcMgmyARhD4',
        },
        {
          Status: 'Published',
          Title: 'Season 3',
          Messages: [
            'M5Dwg3gM0Bo',
            'p5yPQWaT-qA',
            'XNkT_xkleHc',
            '2ziQ_I7Zquk',
            'IKRHb41qbug',
            'Bi0bXtG7WSY',
            'My7xbXsMf0A',
            'IkA__AzcRJ8',
            'jxcXIRRMVgo',
            'QCqDfnfja-U',
            'azq1FW0IwSo',
            'sZPfrNPtZ48',
            'MqCY_17K6FE',
            'ZFKPdH0z6rk',
            '7cEn8YDXcd4',
            '55h3RBRy24U',
            'VPMRctqlgKE',
            'S6Xeq4dM7Co',
            'Ivu6HSd0nPc',
            '1nO0Z-WG88A',
            'QpzGagjsLhU',
            'OgCmSKMrb1s',
            'LetQ5MlKBYQ',
            'EdQbN6bxg8E',
            '0gFTgcSLbYE',
            'OoEM0wbLcyk',
            'TDrBAAAthPE',
            'SMzXdkThUlw',
          ],
          SeasonId: 'J7Q0EgqD3E4',
        },
        {
          Status: 'Published',
          Title: 'Season 4',
          Messages: [
            'l6mRTkbttc4',
            'VlD4F6vLtEk',
            'EC1cK1bcXMY',
            '0xD-kSm764E',
            'YjoNuunS2lE',
            'uBcZPB1np5Y',
            'XmOILB8R6uw',
            'XLVfaxcbpK4',
          ],
          SeasonId: 'sYNUiYCAoTY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'hGPFmF-VlVI',
      Description:
        'If you want to know what the future holds for your world today, you’ve got to look into God’s master script (the Word).',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/your_loveworld_specials.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Legal and Vital Aspects of Christ ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/515155de-2654-40f8-bd73-d5ef92c2083f/the-legal-and-vital-aspects-of-christ.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'x4XsAbe_rso',
          Messages: ['j0SI-ATB0vc'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['C1LgulXxjns'],
          SeasonId: 'xAFYLBh3Co8',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'zGRyGiB12I4',
      Description:
        '"The extent to which we walk in the realities of the higher life in Christ is commensurate to our comprehension of the legal and vital aspects of salvation.\nAn understanding of the salient principles in this classic by Pastor Chris will bring you into an experiential knowledge of the divine life."',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/515155de-2654-40f8-bd73-d5ef92c2083f/the-legal-and-vital-aspects-of-christ.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Power of Thoughts',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/658e449d-a003-4145-b277-7fbff5f00eb0/power_of_thoughts_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'gZOCd70TX4o',
          Messages: ['SPRtLUGBVEc', 'OEOH1HInOMQ'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ALBG7Uty-aY',
      Description:
        'Thoughts are powerful,  they are images clothed in vocabulary and they can come from God,  your mind,  or the devil. Discover how to activate the power of God on your behalf through your thoughts. ',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/658e449d-a003-4145-b277-7fbff5f00eb0/power_of_thoughts_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'RXY0Q2L91_o'],
      Title: 'Sound, Matter and Faith',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dc6d0502-ca46-4cf7-9d88-ea5db0aafcba/sound-matter-and-faith.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 2',
          SeasonId: 'YZd8GZ2CqlM',
          Messages: ['NcQg6-dAFhs', 'TyvB71R4Kyc'],
        },
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['TyT-gTjTQUk'],
          SeasonId: 'WuBEvOWkSmg',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'M7wx3tr2dbY',
      Description:
        "Get to your next level in your walk of Faith as Pastor Chris explicates in this message on the application of Sound,  matter and Faith. It comes as an out-breathing of the Spirit of God to enlighten you on the effective application of your faith. You'll discover that the Word of God not only illuminates your path,  it also imparts faith to your spirit and gives you the cogency of evidence that you’ve got what you desired.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dc6d0502-ca46-4cf7-9d88-ea5db0aafcba/sound-matter-and-faith.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Global Day of Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/global_day_of_prayer_200x200.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Phase 2',
          SeasonId: '4cHl41QUHbo',
          Messages: ['siHS2_-j1Z8', 'rPoIZzHfQys'],
        },
        {
          Status: 'Published',
          Title: 'Phase 3',
          Messages: ['ZJ-bJgoPpgs', 'YctDXDcrvFY'],
          SeasonId: 'cvm8PCfLsy8',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'F0-OUmdcgSs',
      Description:
        'In the place of prayer, your faith rises to the top. Impossibilities become possible.  Your faith is emboldened in the place of prayer. As you watch this excerpt from the Global Day of Prayer with Pastor Chris Oyakhilome DSc, DD, you will understand how to control the things of this world from the realm of the spirit. Learn more about the gathering together of the Church of Jesus Christ at the rapture, the day of Christ and the things that must surely be fulfilled in the earth as prophesied in the scriptures.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/global_day_of_prayer_200x200.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: 'An Exposé on the Year of the Gathering Clouds',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/an_expos%C3%A9_on_the_year_of_the_gathering_clouds_(200+x+200).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'ibiWsMtiVxE',
          Messages: ['iuMRJJw7psw'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '1w1FDxrMk9M',
      Status: 'Published',
      Description:
        'Are you ready for the Lord’s return? Do you know the significance of the cloud of glory in the life of a Christian? Find the answers as you watch this message by Pastor Chris Oyakhilome DSc, DD, which gives insight and knowledge into the theme of the year – The Year of the Gathering Clouds. You’ll also learn from this teaching when the ingathering of the saints will take place.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/an_expos%C3%A9_on_the_year_of_the_gathering_clouds_(200+x+200).jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: 'December 2018 - The Month of Gathering Sheaves',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/47ec5678-743a-4786-a2e1-6ccfba52da9f/December-the-month-of-gathering-sheaves.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '63jdSqo7rD4',
          Messages: ['3244pI0_Waw'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'UOkOw7cqFXQ',
      Status: 'Published',
      Description:
        'It’s time to bring together your idea sheaves and finance sheaves which you may have overlooked because the Lord wants to update your ideas in a way that you can act on them. As you listen to this special message by Pastor Chris Oyakhilome,  the Holy Spirit will show you each sheaf to gather in bringing forth enormous wealth.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/47ec5678-743a-4786-a2e1-6ccfba52da9f/December-the-month-of-gathering-sheaves.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: '5 Facts for Spiritual Effectiveness',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3bbb2bf4-23c6-42fa-a320-edb51b94e39b/5-Facts_for_Spiritual_Effectiveness.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'C8YGIOKfRbk',
          Messages: ['s1Q0Hb2nFdw'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['7XMMaq95ftU'],
          SeasonId: 'yPZukphVihU',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'wiHG8Oyt5Jc',
      Description:
        'The knowledge of the holy brings understanding; and the heart of the simple is opened through the word. Learn from this timely teaching by Pastor Chris,  5 important facts for spiritual effectiveness.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3bbb2bf4-23c6-42fa-a320-edb51b94e39b/5-Facts_for_Spiritual_Effectiveness.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'If These Things Be In You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/003ecb4d-c568-480b-8735-978c191e0a16/if_these_things_be_in_you_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['B2Inr9Koy7U'],
          SeasonId: 'ikqIrbO1ESE',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'P1u0b1RcvR8',
      Description:
        'Learn how to live a fruitful and productive Christian life continually. You will also learn how to strengthen your marriage and personal life with the knowledge of God’s Word.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/003ecb4d-c568-480b-8735-978c191e0a16/if_these_things_be_in_you_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Substitution',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0a7a339a-5541-43f9-b9d3-214561f14478/substitution.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'lgKPPbehR6c',
          Messages: ['aC9Nlc9satk', 'R-ZTQMfv3cs'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'q6gMSn7-lj0',
      Description:
        'Get a deeper insight on how to embrace God\'s righteousness and walk in it from this classic teaching on \\Substitution\\" by Pastor Chris. You will begin to see yourself in a completely new light as you learn to take your rightful place in Christ and live a life full of glory and honor."',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0a7a339a-5541-43f9-b9d3-214561f14478/substitution.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Righteousness',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4f8342e0-28d8-49f2-9cfd-05ade74a48c6/Righteousness.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['Qx6S66X85HU', '1rEAwJKQCIU'],
          SeasonId: 'nySzjBYPSYU',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['JQHQ0a3PuW8', '9ViLjcfV6mw'],
          SeasonId: 'O1mHDKVIYh0',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'w4DtNFmXqNs',
      Description:
        "There’s a life consistent with God’s nature and way of doing things. That’s the life we’ve been called to live,  and it's the life of righteousness. What then is righteousness? Learn from this dynamic teaching by Pastor Chris how to walk in the reality of your righteousness,  and discover how to manifest and administer the will,  purpose,  and character of God in your world.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4f8342e0-28d8-49f2-9cfd-05ade74a48c6/Righteousness.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'The Fullness Of God',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/829147be-8e92-451a-9f76-6ef7b36b4d17/the_fullness_of_God_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['xKfkiNsTfR4', 'shqYy1OIhSw', 'Bj-X5YCH6LA'],
          SeasonId: '4RKNizz4oyI',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['-b-hXsrD9hQ'],
          SeasonId: 'AbbhiergShI',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['8dDStF7qAGk', '0vhixZ87-6U'],
          SeasonId: 'mvTnoOikXio',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'qWb5OzfPtQM',
      Description:
        'Embolden your vision for a better future as you discover the vital tool required to make you greater than your world. Learn how to enlarge your heart to receive all that God has in plan for you. God wants to manifest His manifold wisdom through your peculiar Character: Discover how His life can be expressed through you to your world.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/829147be-8e92-451a-9f76-6ef7b36b4d17/the_fullness_of_God_240.png',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'Prayer of Intercession',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/2a175b70-d3c3-496e-9765-a9bcad5b3e82/The-Prayer-of-Intercession-Part-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '3oxvqf57ynw',
          Messages: ['FVWeRw8aibs', 'JKHxyj-c-Ls'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'uzqGLf_gVL0',
      Description:
        'Through the prayer of intercession God bestows on us the opportunity,  ability and inspiration to make a difference in our world. In this exposé on prayer,  get acquainted with the various rules of prayer and glow in your priestly responsibility as a believer. ',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/2a175b70-d3c3-496e-9765-a9bcad5b3e82/The-Prayer-of-Intercession-Part-1.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0', '6EKZPdy84W0', 'AocoInCV3Qk'],
      Title: 'The Power and Pursuit Of Purpose',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_POWER_OF_PRAYER.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['0dRO5Vn08Io', 'uScFRF8kRSE'],
          SeasonId: 'fIE5IciLnlk',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: [
            'SICbobgP5Kc',
            'M_a2MXvaapM',
            'XBHXTN-SjGw',
            '0pjA86rL0EQ',
          ],
          SeasonId: '1i9bGIa-V7k',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'VQyxP00It8U',
      Description:
        "Did you know that Jesus cares whether or not you love Him? The scriptures tell us the one who loves Jesus will keep his word (John 14:23). Your life is made by the Word and for the Word. Find out from this teaching by Pastor Chris Oyakhilome Dsc, DD what your purpose in life is and how to fulfill God's plan for your life as you walk in the light of the Word of God.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_POWER_OF_PRAYER.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'Who is in Your House?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/who-is-in-your-house.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '91NRuQsRqbY',
          Messages: ['MNDcAR-6b48'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'CloCneyMWpg',
      Status: 'Published',
      Description:
        'Sickness and disease are Satan’s expressions in a human body. But divine health, strength and glory are the manifestations of the Holy Spirit in a person. Your body is your house. You are not a body, You are a spirit being; and without your body you cannot express yourself in the earth. You’ll understand from this message by Pastor Chris Oyakhilome DSc, DD, how to glorify God with your human body and live in health perpetually.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/who-is-in-your-house.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'sTniib-wePM'],
      Title: 'Activating the Power of God',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f6d712f9-d292-450e-b4c2-2d95f1969ea6/Activating_The_Power_of_God.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['b6Inlz7m0C4'],
          SeasonId: 'Txvt1t36sBg',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['Cbpg2O2zzF0'],
          SeasonId: 'xNkCkwbz9lE',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'CaUIsj8gauc',
      Description:
        'The very author of life and the all-powerful God indwells you and this knowledge spurs you into producing results of dominion,  absolute mastery and the demonstration of the power of God. As you watch and meditate on this message by Rev. Chris Oyakhilome DSc,  DD; Activating The Power of God,  you would come to the point where you fully acknowledge and manifest His presence within you.',
      Status: 'Published',
      Captions: ['English'],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f6d712f9-d292-450e-b4c2-2d95f1969ea6/Activating_The_Power_of_God.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Bible Seminar',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3baeab6a-92b5-4e12-8631-ed0afee08da9/bible_seminar_vol1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: [
            'mWMuU1r8D9g',
            '2XWPWhbo7HM',
            'iBl4ajVA3Io',
            'n7gsvpnBzho',
          ],
          SeasonId: '3NYEdKtXgB4',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['UjhZiyyAFVw', '6hLj-OHYsOg', 'U8cfgouXVqg'],
          SeasonId: 'PJsTevRYTwY',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 's1BLLJlFb6s',
      Description:
        'God made you for His pleasure. Find out how to live a happy,  prosperous and fulfilled life continually. Learn how to be the best of you every day as you discover the features in your human spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3baeab6a-92b5-4e12-8631-ed0afee08da9/bible_seminar_vol1_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Psalm 91',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1c0db94c-df10-4a3a-b4bd-6a77819310a9/psalm91_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'ayXksZKkzTw',
          Messages: [
            'kO7dfUCzTCU',
            '4IGSkKEC8fU',
            '0Cm5Itk-xd8',
            '8-1eqphIkgo',
            'H9kZyjzM-rI',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'TqeYJ30Wkwc',
      Description:
        "In a day and time when there's so much trouble around the world,  many are wondering if there's still hope or if God cares about us. Find out from this message what it means to dwell in the secret place of the Most High.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1c0db94c-df10-4a3a-b4bd-6a77819310a9/psalm91_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'The Fourth Man ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/43d421aa-36ff-4716-bed8-8da349830f34/the-fourth-man.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'JAA67oEqAUc',
          Messages: [
            'MwkY8k-Oq4w',
            'ImCXo3rdtUo',
            'tpjgTfrDf6M',
            'HWt6G7ouz5w',
            'lN6Qv5fF_Fs',
            '_xUbewr7SiI',
            '9Yu48NXGG3c',
            'u_0xtLKI1iQ',
            'PzVWCdTS7es',
            'VYzHIbdQYv0',
            'xcRfYwoWgmg',
            'IBq0CQI1Ff0',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ydZxpicPJ1A',
      Description:
        'Who is the fourth man? What is his mission and purpose? What has God entrusted him with? Find out the answers to these and more in this timeless classic by Pastor Chris Oyakhilome DSc,  DD,  that would spur you on a journey of impact and influence in saving souls.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/43d421aa-36ff-4716-bed8-8da349830f34/the-fourth-man.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'April 2017 - The Month for Increasing Knowledge',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3aea2d66-0a4f-4e9d-9fc8-7af10d591ef7/April-2017-the-month-for-increasing-knowledge.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['K7oRey7YTjI'],
          SeasonId: 'fDuPc-O_h1Q',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'uZMeXLc_6MA',
      Description:
        '"Knowledge is the activator you need to move from where you are today to the next level of success,  progress and victory. It is the key to enjoying your inheritance in Christ Jesus. Your knowledge of God’s word will put you over in life causing you to spread abroad in prosperity. Get ready to sing a new victory song as Pastor Chris expounds on how to get yourself filled with the knowledge of God’s will."',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3aea2d66-0a4f-4e9d-9fc8-7af10d591ef7/April-2017-the-month-for-increasing-knowledge.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Knowing God’s Plan for You',
      Thumbnail:
        "https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/knowing-god's-plan-for-you.jpg",
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'FvuNOP4Jq-w',
          Messages: ['tTJPIdekync', '7YLVCZaLKow'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'eSLU4m2sDZk',
      Description:
        'You are God’s own handiwork, recreated in Christ Jesus for good works. God has his brand on you. What are the signs of God’s presence in your life according to God’s report from His Word? Learn to walk in the path that God prepared ahead of time for you to walk in. Get to know God’s plan for your life as you watch this message by Pastor Chris Oyakhilome DSc, DD.',
      Status: 'Published',
      Captions: [],
      Cover:
        "https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/knowing-god's-plan-for-you.jpg",
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Using the Name of Jesus',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/using-the-name-of-jesus.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'XOL0vSRRe30',
          Messages: ['0O0s-MoVSkQ'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: '1MBlfZD8Dx4',
      Status: 'Published',
      Description:
        'Jesus gave us the legal power of attorney to use His name (John 16:23). So, what’s in His name? And why do we have to use it? The name of Jesus is the highest name of power in heaven, earth and in hell. Every knee bows to the name of Jesus. Find out from this special teaching by Pastor Chris Oyakhilome how to live victoriously every day above fear, defeat, failure and the negative influences of this world as you use the name of Jesus.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/using-the-name-of-jesus.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'Now is the Time',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/now_is_the_time_200x200.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'KX0tqkj8NcY',
          Messages: ['NjuRSSUTyjk'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'OecrOtlgCFM',
      Status: 'Published',
      Description:
        '“Now is the Time” is a perfect tool for soul winning and evangelism. This powerful and compelling video guarantees that every viewer who is unsaved decides to receive salvation immediately, while inspiring the zeal in every Christian to share this timely material with the unsaved without reservations.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/now_is_the_time_200x200.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Who is Jesus?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c3d61e52-b351-45d1-b444-80a187a4274d/Who_is_Jesus.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'A2RURLOkWOg',
          Messages: ['AyO1-gV_xx8'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['x9XcKvvR5uY'],
          SeasonId: 'WBBEsuSesKc',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'z7b0HF2DjiE',
      Description:
        'A consummate divine revelation on whom the man Jesus really is. This classic teaching by Pastor Chris reveals His true origin,  His essence and the distinctive inherent divine life He embodies.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c3d61e52-b351-45d1-b444-80a187a4274d/Who_is_Jesus.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Life is Spiritual',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b9ed9c15-8cd5-4e8c-8def-a000458ced50/life_is_spiritual_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'kHHwHPANvlY',
          Messages: ['Gmgw2SCtjX0'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['YLP7Zx7yKTM'],
          SeasonId: 'LO-JN5kXLn0',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'HtyY3fkxDXY',
      Description:
        'Success or failure would depend on who you are and what you know. There are too many questions that man has no answers to: The reason is because man is a spirit,  and human life is controlled from the realm of the spirit. For you to be successful in life,  you must participate in the realm of the spirit. Pastor Chris Oyakhilome unveils in this special teaching the things that you must do to gain control and live victoriously through the spirit.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b9ed9c15-8cd5-4e8c-8def-a000458ced50/life_is_spiritual_240.png',
    },
    {
      Categories: ['4BgKISdOY9s'],
      Title: 'Connected to Divinity',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f1cf761-486a-4f6a-b2c6-05ff8eb3b68d/divine_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'MQHQVvJu9-U',
          Messages: ['xT9Ye2aXAv8'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '8IiuaxdQV88',
      Status: 'Published',
      Description:
        'Do you know that anything that connects with you is an item of blessing? There’s an invocation of power to make good everything that concerns you,  because you’re royalty and you’ve got greatness in you. You’ll understand from this teaching by Pastor Chris how to function in an unending stream of prosperity by the anointing.\n',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f1cf761-486a-4f6a-b2c6-05ff8eb3b68d/divine_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'The Lost Presence',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the_lost_presence(hindi).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'o-XTzGAoyJ0',
          Messages: ['tEw_Zl_urrk'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'XZHoR4EetWU',
      Description:
        'Adam lost his place of fellowship and was separated from his ultimate source. From then on, men over the ages have sought earnestly for the way back to God’s presence. This enthusing message by Pastor Chris unveils the way to the father’s presence, the authority and power we have in the name of Jesus and our glorious inheritance in Christ.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the_lost_presence(hindi).jpg',
    },
    {
      Categories: [
        'RXY0Q2L91_o',
        'N5y75pdx8k0',
        'ZtPhUh6bsak',
        'sTniib-wePM',
        'AocoInCV3Qk',
      ],
      Title: 'Incantations',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/INCANTATIONS.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['KRATpotCkWM'],
          SeasonId: 'gc52mGPAxgE',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'z32cirj55uY',
      Description:
        'When we pray, we implore the mercy of God. We bless the Lord when we pray; our minds are focused on the spiritual. The Lord wants his Church to know the times we are living in and how to function in these last days of the prophetic calendar of the Church in the earth. In this subject on Prayer, Pastor Chris expounds on God’s principles of prayer in incantations: a ritual recitation of words or sounds. You’ll understand how to speak forth mysteries using the right conjurations that bring blessings, prosperity and victories into this world.',
      Status: 'Published',
      Captions: [],
      Cover: 'https://d2dypimxw31xa2.cloudfront.net/albums/INCANTATIONS.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Isaiah 60',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af3f8a56-48fc-455c-86c5-1fa6226a7e49/isaiah_60_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'MaKZ2rxcrzI',
          Messages: ['Bh-aW6azqOw'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'tpKEtsG6_gs',
      Status: 'Published',
      Description:
        'The first verse of the book of Isaiah chapter sixty,  in the King James’ version,  declares: “Arise,  shine; for thy light is come,  and the glory of the Lord is risen upon thee.” These captivating words emphasize a trend in the whole chapter that calls on the Christian to move on and up to the next level of life and living. Pastor Chris declares,  at a point to the audience that “…we are living in Isaiah 60 right now.” Journey with him as he expounds on verse after verse and watch your faith rise to the point where you see yourself living in the glorious words of this wonderful chapter of the Bible.\n',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af3f8a56-48fc-455c-86c5-1fa6226a7e49/isaiah_60_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'Notre Marche par La Foi',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/acefd1f4-dbc4-4e05-ae23-204808881894/ourwalkoffaith.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'fJWsMKupq-o',
          Messages: [],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'osdc6BOTteI',
      Status: 'Published',
      Description:
        '"Le plan de Dieu pour ses enfants cest quils reussistent et prosperent. Malheureusement beaucoup dans leur marche experiment le contraire de cela.\nCet enseignment classique sur la foi parle de tout cela et bien plus,  avec un apercu detaille de ce theme: la foi et son impact dans la vie d\'un chretien.\nApprenons a pratiquer la vie de la foi pendent que Dieu fait de nous les proprietaires ce a quoi il nous a destines."',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/acefd1f4-dbc4-4e05-ae23-204808881894/ourwalkoffaith.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: '7 Things the Word Does for You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/7_things_the_word_does_for_you_(200+x+200).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'uKlsbzfKHJM',
          Messages: ['PUFKZ4Nkq9A'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['AtDlhyYxBdo'],
          SeasonId: 'JJ1gSMDBzfk',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'baXu0a5gbYI',
      Description:
        '"What does the Word of God mean to you? Are you awakened to the potency of the Word?\nIn this timely message by Pastor Chris, discover 7 important things the Word does for you and live triumphantly everyday!\n"',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/7_things_the_word_does_for_you_(200+x+200).jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Anticipating Evil',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/525a2b5e-df6f-4e7e-8803-e7eeed112c5c/anticipating_evil_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: [
            'dWl2HuTm0pg',
            '2XaeLC1wBIo',
            'OW3l7Y3AQ40',
            'ocpKYTqrMe8',
          ],
          SeasonId: 'CFhMWFaPRE4',
        },
      ],
      Author: 'Pst Janea-Joy',
      SeriesId: 'VSNSKky5ItY',
      Description:
        'Learn how to anticipate evil from within your spirit; recognizing the signals of the spirit within you. You will also learn how to educate your spirit to be a sure guide.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/525a2b5e-df6f-4e7e-8803-e7eeed112c5c/anticipating_evil_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: '2012 - The Year of the Word of God',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/957476be-bff6-482b-b9bb-9ddd27cd479a/the_year_of_the_word_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['TY00fOao57U'],
          SeasonId: 'E_aqbmEXFho',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '12NeGx-Rdro',
      Description:
        'The word of God in your spirit will transform you from the state of discontentment to prosperity. Understand that the Word of God in you will bring you into a life of endless miracles and possibilities,  where you never fail.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/957476be-bff6-482b-b9bb-9ddd27cd479a/the_year_of_the_word_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: 'Understanding Faith',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e1f8efcc-286d-46cc-ac47-b1ec4278e121/understanding_faith_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'WwFntisacT4',
          Messages: ['NzxXSYnFH5Y'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['h9rHEs9fJqI'],
          SeasonId: '3_bZ2eKvBys',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ffETUUdy5zk',
      Description:
        'Learn the principles of faith that would guide you through life. Also find out in this teaching how you can overcome challenges on your job,  in your finances,  academics and family through faith in the Word of God.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e1f8efcc-286d-46cc-ac47-b1ec4278e121/understanding_faith_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Topical Teaching Highlights',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3b273775-95d5-4c46-90ba-bf41706ab0d7/Topical_Teaching_Highlight.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '1499jVounIo',
          Messages: [
            'yfzf-VgTLTE',
            'Wu4QLd9KUIk',
            'pIbecDktEQY',
            '13ScBlo5EJA',
            '9w7lEW7vVmY',
          ],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['hq6zpQdaVdc'],
          SeasonId: 'B4ZLs76eXTQ',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'BAHYTlVq2iw',
      Description:
        'As you watch this video,  which contains teaching excerpts from Pastor Chris Oyakhilome,  you will get to understand deep truths about Christian growth and maturity  and how to walk in the complete understanding of this truth.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3b273775-95d5-4c46-90ba-bf41706ab0d7/Topical_Teaching_Highlight.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'The Partnership of the Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8c57239e-1938-4045-843f-7d2753816381/partnership-of-the-spirit.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'uABaXFaVcyI',
          Messages: ['_v4RvQCXl9w', 'pq6i6o1o5Ec'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Ca3MBshCQwY',
      Description: '',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8c57239e-1938-4045-843f-7d2753816381/partnership-of-the-spirit.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak', 'RXY0Q2L91_o'],
      Title: 'Baptisms,  Communion and the Church',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e9011059-91e8-4e53-b514-bfadbef9ef67/Baptisms-Communion-and-the-Church.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: '_Z3LkRFTBCM',
          Messages: ['yQOsMLJfs24'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['j3TMgTIbiwo'],
          SeasonId: 'nHSQ4vkMFLQ',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'LZU4YdkRuKI',
      Description:
        'The teachings and doctrines of Christ are our foundational principles for spiritual maturity and the confidence of our testimonies. In this timely message,  Pastor Chris expounds on the importance and blessings of 3 vital doctrines of Christ; Baptisms,  Communion and the Church.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e9011059-91e8-4e53-b514-bfadbef9ef67/Baptisms-Communion-and-the-Church.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: "Love's Dimensions",
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ed68cc21-7e44-4a28-a79c-d5216a264735/love_dimension_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['yDu8kGzQZ7M'],
          SeasonId: 'Lk7BygDYfW4',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '5WauFWMG5x8',
      Description:
        'Understand how to function in the different dimensions of Love: the breadth,  length,  depth,  and height of love,  for then you will be filled with the Holy Spirit continually,  and your Christian life will be balanced.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ed68cc21-7e44-4a28-a79c-d5216a264735/love_dimension_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Dew of Heaven',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8f4bcdfd-ba2a-45fb-a0df-07981bd7c43b/dew-of-heaven.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'rX_RsCnaZxs',
          Messages: ['W-PuB-FAUvI'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'GMtRwL1J2bs',
      Status: 'Published',
      Description:
        'The Holy Spirit is the administrator of the blessings and benefits of our salvation in Christ Jesus. When you get to know Him,  and become synchronized with Him,  walking in the fullness of the blessings of the Gospel will become your vital experience! In this profound teaching,  Pastor Chris exhorts on the Holy Spirit,  highlighting unique aspects of His ministry in the life of the believer! Get ready for an experience in the supernatural,  and a life of limitless possibilities as you avail yourself of the timeless truths in this classic teaching!  ',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8f4bcdfd-ba2a-45fb-a0df-07981bd7c43b/dew-of-heaven.png',
    },
    {
      Categories: ['yB4dKm3LNec', 'AocoInCV3Qk'],
      Title: '7 Reigns of the Prophetic Timeline (An Introduction)',
      Thumbnail:
        'https://d1zx0zj5kmre28.cloudfront.net/images/covers/7_reigns_of_the_prophetic_timeline.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'WQqtDtfkWC8',
          Messages: ['UV3cRZo4Glg'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '3wKhm93DIGY',
      Description:
        'The teaching on the ‘7 Reigns of the Prophetic Timeline’ reveals where we are at in God’s calendar, so that every Christian knows what to expect and how to respond in these times. Pastor Chris Oyakhilome DSc, DD, expounded on how men were kept in bondage all their life time through the fear of death revealing deep truths on Christ, whose vicarious death and resurrection liberated men from death to eternal life. You will understand from this message the 7 reigns of the prophetic timeline and what the role of the Church is in fulfilling this timing of the Spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d1zx0zj5kmre28.cloudfront.net/images/covers/7_reigns_of_the_prophetic_timeline.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Higher Life Conference Benin',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/02f2a5ae-81a0-4669-9982-9762cb87d816/hlc-benin-day-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '155Yv6J25gg',
          Messages: ['3N0z1JAt76E', 'xHv_V7Boevo', 'kp8SP4tFSlg', 'k2lpiv2Ni4M'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'h0KJd-lfM2E',
      Description:
        'Be anxious for nothing! Fight the good fight of faith! This insightful teaching from the Higher Life Conference Benin with Pastor Chris is an exposé on the vital elements required to activate the law of faith to your advantage.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/02f2a5ae-81a0-4669-9982-9762cb87d816/hlc-benin-day-1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'November 2018 - The Month of Celebrating our Riches',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7b225c23-88ad-4268-a986-8e981872c33c/November-the-month-of-celebrating-our-riches.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'yt0Fp7gpfVg',
          Messages: ['dBvhxjSI7zM'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'gIGThvbAMmY',
      Status: 'Published',
      Description:
        "God's plan is to make the Church if Jesus Christ so rich and powerful in these last days,  where nothing can stop us from achieving our course of preaching the gospel around the world. Watch excerpts from this special meeting with Pastor Chris as he expounds on how to prosper financially.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7b225c23-88ad-4268-a986-8e981872c33c/November-the-month-of-celebrating-our-riches.png',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'The Power of Intercession',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dbafaec2-cb19-4c95-9f49-c528cea38908/the_power_of_intercession_pt1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'fZZA11Dl4lY',
          Messages: ['2vBvwdqorz4', 'rQUM1d4gTc4'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'NLutFiOo1MA',
      Description:
        'God has given you authority to effect changes in your world through intercessory prayer. He’s in pursuit of having all men saved; and He wants you to keep on praying until you have the note of victory in your spirit. Learn from this special teaching by Pastor Chris how to intercede for your city or nation in averting the judgments of God.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dbafaec2-cb19-4c95-9f49-c528cea38908/the_power_of_intercession_pt1_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'Winning the War Within',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f80d72c-71b4-4fbd-97b5-c405323714b9/Winning_the_War_Within.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'wv8v5SyZAxU',
          Messages: ['dmvhYRKGhbQ'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: '3HzF8G00t7s',
      Status: 'Published',
      Description:
        'The changes you desire to see must emanate from within because the character of your spirit is the character of your life. In this instructive teaching by Pastor Chris,  you would learn how to constantly upgrade your life through the cultivation of your spirit in the face of daunting challenges.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f80d72c-71b4-4fbd-97b5-c405323714b9/Winning_the_War_Within.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'A oração do Senhor',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/70a8fd46-6c1f-4a43-a8ae-632dbf4eec74/the-lords-prayer.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'eNdMzs-Pf88',
          Messages: ['GmPCkI5XX-8'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'R75ocLIpVn8',
      Status: 'Published',
      Description:
        'Ao ensinar seus discípulos a orar,  o Senhor Jesus,  em Mateus 6: 9,  esboçou para eles um formato para a oração,  que hoje é amplamente referido como \\O Pai Nosso\\". Mas isso é realmente \\"O Pai Nosso?\\" A mensagem ajudará você a apreender e apropriar-se da importância total de \\"Oração do Senhor\\" e seu benefício espiritual para você hoje como uma nova criação em Cristo Jesus. Você receberá insights sobre os profundos mistérios e realidades de Cristo\tenquanto estiver inspirado e revigorado. por uma vida de oração altamente eficaz."',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/70a8fd46-6c1f-4a43-a8ae-632dbf4eec74/the-lords-prayer.png',
    },
    {
      Categories: ['RXY0Q2L91_o'],
      Title: '2010 - The Year of the Greater Light',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b462f477-7c87-416e-b964-3704018e89b0/year_of_greater_light_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '9bxrT-oFoG4',
          Messages: ['r1yqvuWhNn4'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '6BDdXTDEg94',
      Status: 'Published',
      Description:
        'Zion is the City of the great King. Find out who you are in Zion and how to maintain your confession in the City of the great King.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b462f477-7c87-416e-b964-3704018e89b0/year_of_greater_light_240.png',
    },
    {
      Categories: ['Vk_RSCd3w10'],
      Title: 'Light of the World',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f2357681-6179-47c1-ae43-bd65a394f1eb/light_of_the_world_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '6zxh9eOROBQ',
          Messages: ['8rW_gphm6uE'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'HffkHnUqczE',
      Status: 'Published',
      Description:
        "Soul winning is God’s number one passion. The one who wins souls is wise; and those that are wise shall shine as the brightness of the heavens; and those who turn many to righteousness shall shine as the stars forever. We are working together with the Lord Jesus in giving men's lives a meaning. Learn from this special teaching by Pastor Chris how to shine as a light in your world.\n",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f2357681-6179-47c1-ae43-bd65a394f1eb/light_of_the_world_240.png',
    },
    {
      Categories: ['3fQ2KLtWwpY'],
      Title: 'Questions & Answers With Pastor Chris',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/135b8b4a-5241-49cc-bb56-95e41428d5cb/q&a1-false_real_prophets-picture-1.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'ta0gcANDkdA',
          Messages: ['yLepSGEAC-o', '4aHtpP0C6rQ'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'MktfnTUoyf8',
      Description: '',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/135b8b4a-5241-49cc-bb56-95e41428d5cb/q&a1-false_real_prophets-picture-1.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'September 2022 – The Month of Meditation and Declaration',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/september-2022-the-month-of-meditation-and-declaration.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '3XZqOH6ad-g',
          Messages: ['FF0jWObO4Cs'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Vg7XMmQ77q8',
      Status: 'Published',
      Description:
        'Your mind was given to you as a tool to chart your course in life. What would you rather use your mind for, for evil or for good? In this timely message by Pastor Chris Oyakhilome DSc, DD, you will understand the principles of meditation and how to bring forth realities in the Word through faith-filled declarations.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/september-2022-the-month-of-meditation-and-declaration.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'June 2017 - The Month of Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/edc2b8ac-54dc-40ef-8d2c-08dc6adb1a47/june-2017-the-month-of-prayer.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['00O5nnA2iDw'],
          SeasonId: 'AwNvHlWg9Dc',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '3vqShFrMxww',
      Description:
        "In prayer,  we fellowship with the Father and His Son Jesus Christ through the power of the Holy Spirit. In this video,  you'll find insightful guidelines on prayer,  as Pastor Chris shows us in the Word of God the successful results that can be produced through intercessory prayer.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/edc2b8ac-54dc-40ef-8d2c-08dc6adb1a47/june-2017-the-month-of-prayer.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: '7 Virtues for Persistent Productivity',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6aa9ccfe-5545-464c-b095-e8b9afad36a3/7-Virtues-for-Persistent-Productivity.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['FpXktDmjruE'],
          SeasonId: 'DVI44iYlf3Q',
        },
        {
          Status: 'Published',
          Title: 'Portuguese',
          Messages: [],
          SeasonId: '5S1ROLxDy9o',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['62YGai3rXNA'],
          SeasonId: 'qoFEyUHLu8Y',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'mG9el9rF6Os',
      Description:
        'The secret for Persistent Productivity is revealed! \\For if these things be in you and abound they make you that ye shall neither be barren nor unfruitful in the knowledge of our Lord Jesus Christ (2 Peter 1: 8).” In this message Pastor Chris expounded on the 7 vital virtues needed for productivity. Discover how to manifest these virtues walk in greater consciousness and flourish on all sides."',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6aa9ccfe-5545-464c-b095-e8b9afad36a3/7-Virtues-for-Persistent-Productivity.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Serving through the Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6ae0a094-3c1a-47c8-bb15-9fa29b7faef1/serving_through_the_spirit_part1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['_ooxBZ0nJbE', 'LxSRQHKs98w'],
          SeasonId: 'w6TfelJeY8c',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'Z_I-vFJn1xU',
      Description:
        'Having accurate understanding of God’s word is important for effective Christian growth and maturity. Learn from this teaching how to build the right character for a prosperous and successful life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6ae0a094-3c1a-47c8-bb15-9fa29b7faef1/serving_through_the_spirit_part1_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: '7 Amazing Blessings of the Gospel ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/15abc794-2800-4b51-80e2-b7d14ab15bfa/7-amazing-blessings-of-the-gospel.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'gmdhoETiZpk',
          Messages: ['aXjg6Evo48c'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['90F-VlovnFk'],
          SeasonId: 'BimexbWmmp4',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '6U4tSVyXrmc',
      Description:
        'As an associate of the God-kind,  your human life has been supplanted and substituted with divine life. This timely exposé by Pastor Chris unveils to the new creation the 7 Amazing Blessings of the Gospel,  which we have received in Christ Jesus.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/15abc794-2800-4b51-80e2-b7d14ab15bfa/7-amazing-blessings-of-the-gospel.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: 'Benedictions',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3899cdc3-05a7-48d8-8314-a055f9ff7dbc/Benedictions.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'oKQERrEa_8I',
          Messages: ['rFHOgJpfmZc'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['dXekiQfZ464'],
          SeasonId: 'lagn-BwcUN0',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['6P4cvdYEGsQ'],
          SeasonId: 'vCATQ5LN5nk',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'o9Or51O7_Gs',
      Description:
        'A benediction is an invocation of blessings,  usually in words of prayer. In this insightful teaching by Pastor Chris,  learn more on the meaning of a benediction,  who can bestow a benediction,  and the power of a benediction.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3899cdc3-05a7-48d8-8314-a055f9ff7dbc/Benedictions.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', '6EKZPdy84W0'],
      Title: 'The Year of Lights',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/96631855-133e-46b0-b97b-50eedf3de587/2019-the-year-of-lights.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'OCNcD2felPE',
          Messages: [
            't8wUf_5i6h0',
            'x0vDd2A-Cbo',
            'WlKAwffUImc',
            'QQ6wCDV2vYI',
            'cvrnZGrjkYU',
            'a5kRGCDtx5Q',
            'vD0r9is2tjA',
            '59mWTSOGZi0',
            'FckKt-fnK2U',
            'sUzLDEOtwbw',
            '28ak_rS1hY0',
            '5p8ELKjTpkw',
            'eDiSZhike04',
            'rQbl-yCWbFk',
            'k_TtCC4fE7k',
            'OLONSqwMa8E',
            'BhqYnDfe1nQ',
            '3eGxKSvjs_o',
            'Ac6nuJvJtWY',
          ],
        },
        {
          Status: 'Published',
          Title: 'Study',
          Messages: [
            'l6bGOF0tRy0',
            'G_WBqVL8wus',
            '60h4E4PJ0Aw',
            'pLATWCW2_8s',
            '78gdwdss_ss',
            'UNIjs6xdz80',
            '8j96fxR4jtY',
            'xWsr_rzE_r8',
            'D2D0Hixz2-8',
            'xg07GnWK_wQ',
          ],
          SeasonId: 'THYCrlc9TK0',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'esTcMqYn_D0',
      Description:
        '2019 has been declared to be the year of lights. Illumination and direction have come to your life through these prophetic words from the man of God,  Pastor Chris Oyakhilome DSc,  DD. Relive the moments as you listen to this inspiring message that will bring you to positions of leadership,  empowerment and honour.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/96631855-133e-46b0-b97b-50eedf3de587/2019-the-year-of-lights.png',
    },
    {
      Categories: ['RXY0Q2L91_o'],
      Title: 'Being Led by the Spirit (An Introduction)',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3926a253-d19c-4015-9ed0-95b0982e128c/being_led_by_the_spirit(An_Introduction).png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'G0VOwwh3nnw',
          Messages: ['DBTxmIRtlKQ'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['DTdf0OWl3y8'],
          SeasonId: 'cUjI1Q3TJjo',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['zqBm4P8MMnU'],
          SeasonId: 'qq_BFvU-QFY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'LIbsGVTGYmE',
      Description:
        'There’s no Christianity without the Holy Spirit. Yielding yourself to be led and controlled by the Spirit is your guarantee of a life of limitless potentials,  and endless possibilities. Learn in this timeless teaching how you can maximize your potentials in life and be all that God created you to be,  as you allow the Holy Spirit lift your vision,  and guide you in the path of greatness. This message will position you to win every day in life,  as you fulfill God’s dream for your life,  to the glory of His Name! ',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3926a253-d19c-4015-9ed0-95b0982e128c/being_led_by_the_spirit(An_Introduction).png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Ministering to Soul Winners',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/81f0a13e-ba57-485e-adee-44196e9e71b9/Ministering-to-Soul-Winners.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: '8P-kEy6W27w',
          Messages: ['lhbm6Hysg3w'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['hUxxn2E9hZ8'],
          SeasonId: 'wrL0gy9oIr4',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'NoH2Ch2xq0A',
      Description:
        "The Gospel is God's only saving power,  but to effectively communicate the message of this gospel,  you must have clarity,  conviction and possession. In this timely message by Pastor Chris,  discover deep truths about the gospel and become better equipped to witness for the Lord all around the world.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/81f0a13e-ba57-485e-adee-44196e9e71b9/Ministering-to-Soul-Winners.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: '5 Blessings in His Name',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/12569cef-2b0e-42bb-86c8-ab68df298ade/5-blessings-in-his-name.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'w8qia2WbZXk',
          Messages: ['b0juhrZuT14'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['0vMWIolJ47k'],
          SeasonId: 'IRxc4GKOknc',
        },
        {
          Status: 'Published',
          Title: 'Italian',
          Messages: ['NfsvW6xQo-0'],
          SeasonId: '-dVmeCOo8K8',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'DqxQh0sJFC8',
      Description:
        'There are no limits to what you can achieve,  absolutely no restrictions to what you can enjoy when you understand the blessings in the Name of Jesus. In this timely message by Pastor Chris,  you would discover 5 Blessings in His Name and the consciousness of these blessings will cause you to live victoriously everyday.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/12569cef-2b0e-42bb-86c8-ab68df298ade/5-blessings-in-his-name.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Called to Fellowship',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bb87c00e-3598-484e-bd77-0ec97c964a10/called-to-fellowship.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'qsqJfc5UuM8',
          Messages: ['UgKWBJqU2vY'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '3qn9SwiPQhU',
      Status: 'Published',
      Description:
        '“If you’re a joint-heir with Christ,  what in the world could you be asking for?” Pastor Chris challenges in this thought-provoking and inspiring teaching. As a Christian,  you’ve been called into a unique fellowship with God,  and your knowledge of that oneness with Him is what truly sets you apart as a victor in life. This message-Called To Fellowship,  will help you explore the profound depths of your union with the Father and give you an enriched understanding of it.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bb87c00e-3598-484e-bd77-0ec97c964a10/called-to-fellowship.png',
    },
    {
      Categories: ['sTniib-wePM', 'N5y75pdx8k0'],
      Title: 'Prevailing Prayer',
      Thumbnail:
        'https://d1zx0zj5kmre28.cloudfront.net/images/covers/prevailing_prayer_part_2.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'peZcg4jFIro',
          Messages: ['uPqDcnfdg5U', 'znQid4SC090'],
        },
      ],
      Author: 'Brother Des',
      SeriesId: 'RA0SCtrFVTM',
      Description:
        'The value of a soul is the blood of Jesus Christ. You’re a co-labourer together with God,  and God is about building lives; He’s about saving souls. Learn from this timely teaching by Pastor Chris how to produce the harvest of souls that you desire in your cell or church through prevailing prayer.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d1zx0zj5kmre28.cloudfront.net/images/covers/prevailing_prayer_part_2.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Year of Triumph',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/a1212d1a-b386-4686-8484-03301f17efb3/yr-of-triumph.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'MF_7Oh6Xt1Y',
          Messages: ['-4M7jEiH-sQ'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'aJ4gtmYxgUo',
      Status: 'Published',
      Description:
        'Now thanks be unto God,  which always causeth us to triumph in Christ,  and maketh manifest the savour of his knowledge by us in every place (2 Corinthians 2:14). Watch in this video,  excerpts from the December 31st global service with Pastor Chris,  as he leads us in triumphal procession into the glorious year 2015.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/a1212d1a-b386-4686-8484-03301f17efb3/yr-of-triumph.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'October 2019 - The Month of Ministry',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d2493196-5bf0-4543-96c2-69c421faa4c0/October_2019_The_Month_of_Ministry_1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'jU4djhvk7Kw',
          Messages: ['10aHq-sBsy8'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '7fjMnyv6aMU',
      Status: 'Published',
      Description:
        "The month of October 2019 has been declared by the Spirit of God through the Man of God,  Pastor Chris Oyakhilome,  to be the month of Ministry. You're in office; and the Lord has commissioned you to fulfill a divine responsibility in the earth. Find out more in this excerpt from our Global Communion Service.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d2493196-5bf0-4543-96c2-69c421faa4c0/October_2019_The_Month_of_Ministry_1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Giving Thanks',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b19e0a26-ff6e-4a43-9894-16c92bf89fea/Giving-Thanks.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '5OjT3a8ui6I',
          Messages: ['BrEd0xUkBIY'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'uHpQvHcfDV4',
      Status: 'Published',
      Description:
        'In this instructive message,  you would discover the importance and rewards of giving thanks,  as Pastor Chris  teaches extensively from the scriptures with practical examples. ',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b19e0a26-ff6e-4a43-9894-16c92bf89fea/Giving-Thanks.png',
    },
    {
      Categories: ['sTniib-wePM', 'ZtPhUh6bsak'],
      Title: 'How to Make Power Available for a Change',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d162fb72-963a-499e-a7a5-9923480216a3/how_to_make_power_available_for_a_change_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['nJjDcqtKd_4'],
          SeasonId: 'XpWfOOC_9gs',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'keycxHs7HqM',
      Description:
        'The earnest,  heartfelt,  continued prayer of a righteous man makes tremendous power available; and this power is dynamic in its working. Learn from this teaching how to press in your prayer and take a hold of the change that you desire in any area of your life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d162fb72-963a-499e-a7a5-9923480216a3/how_to_make_power_available_for_a_change_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', '4BgKISdOY9s'],
      Title: 'Increase in Fortunes',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9d94c339-3460-4d53-ad92-2bb20e86c185/increase_in_fortune_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'Ml6XNFc03lg',
          Messages: ['KxbYSS8HllE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'kFewpFvvY74',
      Status: 'Published',
      Description:
        'Do you know you were born with wealth untold? Learn how to multiply your finances no matter the kind of job you do.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9d94c339-3460-4d53-ad92-2bb20e86c185/increase_in_fortune_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Priesthood and Sacrifices',
      Thumbnail:
        'https://s3.amazonaws.com/lwnm_pastorchrisdigitallib/product_jackets/priesthood_sacrifices_v150.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '23wVfEkQOAk',
          Messages: [
            'G8SxDUnDFfY',
            'SOQFdhVQLMk',
            'I1-Q1dq3Eoc',
            'b3AFwBEFOqM',
            '7SNkX6QKKtE',
            'KyS3qJmG7aU',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'bV5Xpm7LdfY',
      Description:
        'Do you know you are a King-priest? Learn how to function in your office as a King-priest who offers spiritual sacrifices to God. You will also learn how to present your body to God as an act of spiritual worship.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://s3.amazonaws.com/lwnm_pastorchrisdigitallib/product_jackets/priesthood_sacrifices_v150.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'October 2022 – The Month of Peace',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/October-2022-the-month-of-peace.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'r89GfoqeSv8',
          Messages: ['g5z76Yv3460'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'R-AJY-lMxuM',
      Status: 'Published',
      Description:
        'When was the last time you spoke peace? When was the last time prophesied peace to your environment? The scriptures said in John 14:27, “Peace I leave with you, my peace I give unto you: not as the world giveth, give I unto you. Let not your heart be troubled, neither let it be afraid.” You will understand from this message by Pastor Chris Oyakhilome DSc, DD, how to activate the peace of God for your life, city and nation.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/October-2022-the-month-of-peace.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'July 2017 - The Month of Expansion',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6180b594-21e7-4caf-b1a3-44a98ee33773/July-2017-the-Month-of-Expansion.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'mj07HoF4jcM',
          Messages: ['m71eQ9HLZVE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'GaLoZrmwc8I',
      Status: 'Published',
      Description:
        "For Thou shalt break forth on the right hand and on the left; and thy seed shall inherit the Gentiles,  and make the desolate cities to be inhabited (Isaiah 54:3)'. In consent with the Word of God,  your expansion is limitless and your blessings cannot be quantified. Get ready to expand in all ramifications as Pastor Chris brings to you the word of God that will propel you into a new level of expansion.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6180b594-21e7-4caf-b1a3-44a98ee33773/July-2017-the-Month-of-Expansion.png',
    },
    {
      Categories: ['sE72qV3sqJ0', 'Vk_RSCd3w10'],
      Title: 'Cell Ministry',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/cell_ministry.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '6l49mVayZ8g',
          Messages: ['UZJUhX4ceUM'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '2erqIDfwVZU',
      Status: 'Published',
      Description:
        'The importance of the Cell Ministry to church growth cannot be overemphasized as it is a necessary part of the body. Youl. Learn from the special video what the cell Ministry is about and its function in the body of Christ.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/5f452cb873312e02aef10900/cell_ministry.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'sE72qV3sqJ0'],
      Title: 'The Year of Flourishing',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8adc8da3-3cac-4490-9cbb-83b2953bb4b9/The_Year_of_Flourishing_png.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['9RyqJn7_YAg'],
          SeasonId: '3LTAbQp03MM',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'jlDAnR9kKnc',
      Description:
        "God's plan for every season is revealed through His given Word. Every December 31st Night,  millions gather from all around the world with high expectations to receive that creative Word of God that will usher them into the new year. So it was as the man of God declared 2017 as the Year of Flourishing during the December 31st Night Global Service. The year is characterised by 3 things,  Luxuriant Growth,  Significant Attainments and Persistent Productivity. Learn more and relive the inspiring moments of that service as you enjoy watching this video.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8adc8da3-3cac-4490-9cbb-83b2953bb4b9/The_Year_of_Flourishing_png.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Where is Jesus?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/where-is-jesus.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'yFiwgncFRqE',
          Messages: ['h87Os5z9YYc'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: '2XSi8CA4G-8',
      Status: 'Published',
      Description:
        'Jesus was declared to be the Son of God by the resurrection from the dead. He came from God, but many didn’t know this. As you watch this message, you will understand as Pastor Chris expounds on the revelation of Jesus Christ and His purpose in the earth.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/where-is-jesus.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'The Entrance of Thy Words',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b002a526-4e69-403e-a630-0f2072338ba3/the_entrance_of_thy_word_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['ysytWtqiz7Y'],
          SeasonId: 'p6q4Qu-NIKI',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Zn69Oqs-VNM',
      Description:
        'Learn how to apply the word of God for any situation in life through the Power of Meditation.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b002a526-4e69-403e-a630-0f2072338ba3/the_entrance_of_thy_word_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Au Service de Seigneur',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/909a8146-fe54-4749-a280-8d9e98aa3467/m2dL.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'xKZFWJ5N-MM',
          Messages: ['oCW19jg5n1s'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'JgzJaDj4aKk',
      Status: 'Published',
      Description: '',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/909a8146-fe54-4749-a280-8d9e98aa3467/m2dL.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: '7 Great Confessions of our Solid Front',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/36317d87-2cd6-4890-a7c4-031d39f345e3/7-great-confessions-of-our-solid-front.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'LRxWSiRF3i8',
          Messages: ['oCmUxlVUev0', 'a8thzN604F8', 'G5A5AStT5iU', 'FvuzDwkYybs'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: [
            'VQQmc0nNDd0',
            'Oa9zO0n7vuM',
            '2KlRwulLvxQ',
            'qexsYHHRhRg',
          ],
          SeasonId: 'JSLzvlhPXW4',
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['VGkYJoMvuZM'],
          SeasonId: 'hxk_zkkbklY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'VggZ_d9lbj4',
      Description:
        'The confession of our Solid fronts is the declaration of our faith-filled words that brings us into the life of perpetual victory. Learn from this timely teaching by Pastor Chris the 7 key principles that mark our solid fronts.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/36317d87-2cd6-4890-a7c4-031d39f345e3/7-great-confessions-of-our-solid-front.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Christ Consciousness',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/adb0a133-26f1-47f9-9710-ece299441a14/christ-consciousness-v1p1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'xAyJRxRAMkQ',
          Messages: ['gd7kxQE9cJw', 'cpMqT7Yd1gc'],
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['wit_gzCySOs', 'JZhKbNX4tRM', 'cRr5AZJBFag'],
          SeasonId: 'SG9yGt-WPm8',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['a9YDIPXK7uM', 'LCbaswhU1Iw'],
          SeasonId: 'o2gFy0vuwvY',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'FhJNWOLxlcQ',
      Description:
        'Your success as a Christian is dependent on your knowledge of who Christ is and of His grace that has been bestowed upon you. Learn from this special teaching by Pastor Chris the consciousness that makes you a master over life’s situations.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/adb0a133-26f1-47f9-9710-ece299441a14/christ-consciousness-v1p1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Advancing in Life',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/94b647cb-85bf-4077-a585-c41ceb5e6eee/advancing_in_life_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['hwt5WYAEN6Q'],
          SeasonId: '3arM6_4Ju9A',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 't4T5-JP9hdA',
      Description:
        'The Word of God makes you wise in the affairs of life,  if you meditate on it. It will bring you sagacity of the spirit,  which only a child of God can have. Find out from this teaching how to saturate your spirit with the Word of God and produce extraordinary results in your life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/94b647cb-85bf-4077-a585-c41ceb5e6eee/advancing_in_life_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'sTniib-wePM'],
      Title: 'The Importance of Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/abd55089-67fd-4928-b65d-219d7a8a3539/the_importance_of_prayer_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['gGWl3ZNJL60'],
          SeasonId: 'hJ_xmTJkOcY',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'K7qshyNB0sw',
      Description:
        'Our spiritual lives depend so much on our communication with the Lord. When we take time off everything else and give Him our attention,  He fellowships with us. Learn from this teaching the importance of prayer and fellowship with the Holy Spirit,  and the glory of God will be manifested in your life.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/abd55089-67fd-4928-b65d-219d7a8a3539/the_importance_of_prayer_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Christ’s Purpose in You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af9f2433-8422-4d41-8c5f-99ec55138b35/christ_purpose_in_you_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'g4tRdcNNzpo',
          Messages: [
            'R8KedZljfk4',
            'F1ySvltFCSI',
            'IuW-hgu1TZs',
            'dVVxLrUBz8E',
            'JNEOgDbZL0k',
          ],
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: [
            'QPl9TZp-tcU',
            'SXtyNugDQk0',
            'u-d8zhSxx3c',
            'BYwqPw03BOU',
            'qocrGyFxP-U',
          ],
          SeasonId: 'f4kWjxFpapY',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: [
            'GIMqWGR4-T8',
            '64neTFJiIkM',
            'E_CcQQ2n9Cs',
            'SdY5vMbsvZc',
            'XqABBOmzV58',
          ],
          SeasonId: '_3cFo8t1GkY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'gjNj0byudR0',
      Description:
        'Living a successful life in Christ is available to everyone who will study God’s word. Learn how you can take a hold of the future and change your world with God’s word and be brought into a new life of health,  righteousness,  success and wealth in Christ.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af9f2433-8422-4d41-8c5f-99ec55138b35/christ_purpose_in_you_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'The Ministry of the Holy Spirit in You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c532b44e-b7c7-472b-a5fb-fc2989117153/the_ministry_of_the_holy_spirit_in_you_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['5Er9cScw77E'],
          SeasonId: 'hOSok4KOANM',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'Z87PW6xIwwM',
      Description:
        'The Holy Spirit is the one who brings you to the place of rest. He leads you in the life of faith,  and makes you of quick understanding in the fear of the Lord. Find out from this classic teaching how to live an authentic Christian Life through the ministry of the Holy Spirit in you.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c532b44e-b7c7-472b-a5fb-fc2989117153/the_ministry_of_the_holy_spirit_in_you_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o'],
      Title: 'Partnership of the Spirit ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/df7e78c7-3992-47e5-999a-14cee3ca8936/partnership_of_the_spirit_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['aNFo2laUKb4', 'eWZwKmyeD4Y'],
          SeasonId: 'ensfJlpkMWQ',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'wDkAUVT2Qr4',
      Description:
        'Do you know that fellowship with the Holy Spirit leads to partnership? And as workers together with the Holy Ghost,  He guides us every step of the way,  as we commit our plans to Him. Learn from this timely teaching by Pastor Chris how to keep your responsibilities in the house of God by the Holy Ghost.\n',
      Status: 'Published',
      Captions: [],
      Cover: '548962be61706932ba043a02',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'A Special Teaching on Fellowship',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1c918c71-6551-463e-aed9-b5ab8161ce1e/a_special_teaching_on_fellowship_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['wv_ayfRn0JM'],
          SeasonId: 'aL023cUQ-5U',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'lSoEsLqVuXM',
      Description:
        'Fellowship brings us into a oneness with God. It is a participation or association with divinity. Find out from this teaching by Pastor Chris the three important classifications of fellowship\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1c918c71-6551-463e-aed9-b5ab8161ce1e/a_special_teaching_on_fellowship_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak', 'RXY0Q2L91_o'],
      Title: 'Ministering To The Lord',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9d18661b-25a8-4837-be7e-9b2695fe0c57/ministering_to_the_lord_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['B05FjZUa7oQ'],
          SeasonId: 'Kh6anPJ1UcI',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ZX1g0pNN-IU',
      Description:
        'In this life-changing teaching,  Understand the mind of God in ministering to Him. Discover how educated your spirit can be when you minister to the Lord and get clear guidance from Him.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9d18661b-25a8-4837-be7e-9b2695fe0c57/ministering_to_the_lord_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'What Manner Of Man Is This',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f09c4fa1-1849-498c-a169-44f362623c92/what_manner_of_man_is_this_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['-KPxw-_JIfI'],
          SeasonId: '9y08JRqGhO4',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '3es0mjLRNNo',
      Description:
        'Man is a dynamic creature who changes according to available influential factors. Understand the factors necessary for your transformation in becoming a wonder to your world.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f09c4fa1-1849-498c-a169-44f362623c92/what_manner_of_man_is_this_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Spiritual Understanding',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0aac2953-50e7-4e9b-9435-bbf4b0a43e67/spiritual-understanding.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'WaqXlDAHcCY',
          Messages: ['VeozwUGfmEQ'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'MnhTH4dBZ9Q',
      Status: 'Published',
      Description:
        'You are not trying to be a success,  success is your birthright! You are not on your way to the glory land,  you were born there! You were created for GLORY and EXCELLENCE. As you listen to this insightful,  power-packed teaching titled: Spiritual understanding,  Get impacted! Increase in wisdom and in the accurate knowledge of God and be fruitful and productive in every good work. ',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0aac2953-50e7-4e9b-9435-bbf4b0a43e67/spiritual-understanding.png',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'Cultivating a Spirit of Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/45eebdec-1cd1-447b-b400-79295df5f35c/cultivating_a_spirit_of_prayer_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'hwPsL_Gaw8Y',
          Messages: ['tsEOhJKR9b0'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'WNsB0QFES-M',
      Status: 'Published',
      Description:
        'Do you know that through a strong desire you can invite the presence of the Holy Spirit into your life? Understand that as you intercede for others through prayer you prevail with God first before you prevail with men. Learn from this special teaching by Pastor Chris how to cultivate a strong desire for the Word and the Spirit of God through prayer.\n',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/45eebdec-1cd1-447b-b400-79295df5f35c/cultivating_a_spirit_of_prayer_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'The Word in You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/549d2026-7e9e-4fbf-abcf-f89205c83dd7/the_word_in_you_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'VoUuUQBHugk',
          Messages: ['GHBfbuWTfwc'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['tyTbgFl28y0'],
          SeasonId: 'RyIWtpueaxM',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ODpMXBYhEko',
      Description:
        'The word of God in your spirit will transform you from the state of discontentment to prosperity. Understand that the Word of God in you will bring you into a life of endless miracles and possibilities,  where you never fail.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/549d2026-7e9e-4fbf-abcf-f89205c83dd7/the_word_in_you_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0', '4BgKISdOY9s'],
      Title: 'How To Take Possession Of What Belongs To You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/834accca-8eb1-45d7-9395-9fdcd670c59d/how_to_take_possession_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['3L8ZJ8b9JUw', 'MLhfvI_kjww'],
          SeasonId: 'rqevaExCwq0',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['vDnderl9-M4', 'uwW7953lH1Q'],
          SeasonId: 'sDaxPmEMtT0',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['CPmUerN_ACQ', 'JJz3wh5x0MQ', 'sHmD5Hw9XRc'],
          SeasonId: 'v_S2-XD6fgA',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'yydiSSmsbbk',
      Description:
        'Learn how to frame your world with the Word of God and learn how to reign over life’s circumstances by speaking faith-filled words.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/834accca-8eb1-45d7-9395-9fdcd670c59d/how_to_take_possession_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'An Exhortation on the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/29385989-15e7-416e-9248-f668b4340695/an_exhortation_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'NJpPWyXFbwg',
          Messages: ['VSDrua4QGjM'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'GKkIoe1kC_0',
      Status: 'Published',
      Description:
        'The Word of God is given to us to mold our character. The importance of a good relationship with the Word cannot be overemphasized in the life of a Christian. Learn from this special teaching by Pastor Chris how to study the Word of God and produce the results that the Word talks about in every area of your life.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/29385989-15e7-416e-9248-f668b4340695/an_exhortation_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Power of Meditation',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/aaaeb26d-9c42-4fa3-9a10-5278136dd9cb/the-power-of-meditation.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'kvmzXCQO9-s',
          Messages: ['kWrRcsYFmWA'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'w0ISMJp6zbI',
      Status: 'Published',
      Description:
        "Create the reality you want by changing the rudiments of your mind through Meditation. Meditation transforms you completely,  giving you the language of the righteous. In this inspiring teaching by Pastor Chris,  learn how to use meditation to change and re-chart your course in life to align with God's purpose for your life.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/aaaeb26d-9c42-4fa3-9a10-5278136dd9cb/the-power-of-meditation.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Cultured by the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4d8087be-689b-4c35-97ee-98e1f1262cb0/cultured-by-the-word.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: '63hV3LMrCC0',
          Messages: ['x3jnRY-E1x4'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['PXMRwgfBu9w'],
          SeasonId: 'U7wL9mExTvM',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'YOxcYaUJhis',
      Description:
        'People’s lifestyles,  behaviors,  and ways of doing things are often peculiar to the cultures in which they’ve been raised. However,  as a child of God,  born of His Word,  He expects you to have and be identified by a heavenly culture,  rather than a transient,  worldly culture. In this inspiring teaching,  Pastor Chris shows you how this happens through your spiritual upbringing in God’s Word. You’ll discover that as you learn God’s Word,  you see through the Word,  understand by the Word,  renew your mind with the Word,  and replace your worldly culture with the culture of the Word. Get ready to have God’s Word minister faith to your spirit and culture you for the life of the Kingdom of light!',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4d8087be-689b-4c35-97ee-98e1f1262cb0/cultured-by-the-word.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'sTniib-wePM'],
      Title: 'February 2017 - The Month of Prophecy',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/071daf06-22f3-4186-9e1e-cdfc71defd9f/The_Month_of_Prophecy.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['3L6ShHAPun8'],
          SeasonId: 'jRF1s6M1pFw',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'h8sIZ4A26Bc',
      Description:
        'Prophecy can be clearly defined as speaking by divine inspiration,  speaking forth-divine revelation,  foretelling future events and giving divine instructions or teachings. In this insightful and inspirational teaching,  Pastor Chris teaches on PROPHECY.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/071daf06-22f3-4186-9e1e-cdfc71defd9f/The_Month_of_Prophecy.jpg',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'Perspectives In Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4d67a6d7-9f35-4a35-8190-9486290fde56/perspectives_in_prayer_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Perspectives In Prayer',
          Messages: ['dLy_8pZUhV0'],
          SeasonId: 'UTwotxf_Wmk',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'wz6becXjhXc',
      Description:
        'Understand the fundamental principles of Prayer and how to act on these.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4d67a6d7-9f35-4a35-8190-9486290fde56/perspectives_in_prayer_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Do You Believe in Miracles?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af939714-3589-4b7d-9092-50fd727691f2/do_u_believe_in_miracles_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '42er5i_zT9Q',
          Messages: ['koxv_6_JM0U'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ETmas6HB-Ls',
      Status: 'Published',
      Description:
        'Someone asked a question,  do you believe in these miracles? The Bible says in 1 Corinthians 12:8-10,  ‘For to one is given by the Spirit the word of Wisdom; to another the Word of knowledge by the same Spirit; To another faith by the same Spirit; to another the gifts of healing by the same Spirit; To another the working of miracles…’ When you watch this video,  you will have all your questions answered and be transported to a new realm of glory,  because Jesus Christ is the same yesterday,  today and forever.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/af939714-3589-4b7d-9092-50fd727691f2/do_u_believe_in_miracles_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: '7 Factors for a Healthy Spiritual Life',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/34e1d6f1-6acd-41b0-902f-524e318e72c6/7-Factors-for-a-Healthy-Spiritual-Life.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'qHmnnyf-HVE',
          Messages: ['iWRsAKAwm20'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '_LpWVwopzPI',
      Status: 'Published',
      Description:
        'God is a Spirit and man is a spirit,  but for man to participate fully in divinity and reign in the earth,  he must have a rich and healthy spiritual life founded on the word. Get to know the 7 Factors for a healthy spiritual life in this teaching by Pastor Chris.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/34e1d6f1-6acd-41b0-902f-524e318e72c6/7-Factors-for-a-Healthy-Spiritual-Life.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Wisdom of the Just ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e70ed627-6de9-453e-817f-68859dd485d7/the-wisdom-of-the-just.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'gwiZfwhpu1E',
          Messages: ['zo1v1omqw4o'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['2XCM-nvXTq8'],
          SeasonId: '3qz6vAirVzA',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'y-xdzcnIJpk',
      Description:
        'Learning to be moved by the Word,  taking actions on the basis of the Word and making adjustments because of the Word should be the aspiration of every child of God. Get exposed to the highest form of wisdom in this timely message by Pastor Chris and get results that can only come by the Word of God.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e70ed627-6de9-453e-817f-68859dd485d7/the-wisdom-of-the-just.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'sE72qV3sqJ0', 'Vk_RSCd3w10'],
      Title: 'Every Tree A Forest',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f1a3b8cd-ba69-4a64-94a5-fad0571666ec/forest_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '2Zl0dLH4Kig',
          Messages: ['MkHZSM3PrXA'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '-sS_3AkNk6Q',
      Status: 'Published',
      Description:
        'Do you know you’re a tree of righteousness? As the word of God declares that the fruitful field shall become a forest,  so are you producing other trees of righteousness. Learn from this teaching by Pastor Chris how to bring forth trees of righteousness from the nations of the earth.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f1a3b8cd-ba69-4a64-94a5-fad0571666ec/forest_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'How to Use Your Faith for the Gospel',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/eb774149-c349-4761-b234-8b62188353f1/How-to-use-your-faith-for-the-gospel.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'oAA-pGQqqzg',
          Messages: ['pdxgWpjGGlk'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['bzVJh6oa7KE'],
          SeasonId: 'Hy3XZgwhXqY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '1H3MhQ5iJ38',
      Description:
        '“Only let your conversation be as it becometh the gospel of Christ: that whether I come and see you,  or else be absent,  I may hear of your affairs,  that ye stand fast in one spirit,  with one mind striving together for the faith of the gospel (Philippians 1:27).” In this glorious teaching,  discover and be inspired on how to incite your faith for the gospel,  haven been built up spiritually,  evidently striving for the faith of the gospel; speaking forth the word in the face of challenges.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/eb774149-c349-4761-b234-8b62188353f1/How-to-use-your-faith-for-the-gospel.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o'],
      Title: 'Christ in You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dd24af37-d272-4fcc-ab01-2db5e56af454/Christ-in-You.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'vIv0yo_D6h8',
          Messages: ['UFdSGnWUT_8'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['1xFB5zHslO8'],
          SeasonId: 'MOi6LXE_JaM',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Opq8aptIZnc',
      Description:
        'Spiritual power is activated through knowledge and proclamations.  Discover and actualize your true potentialities through the knowledge this message brings of the biggest thing that ever happened in this world; the possibility of the Holy Spirit to come and live in human beings.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/dd24af37-d272-4fcc-ab01-2db5e56af454/Christ-in-You.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'An Exposé on the Year of Preparation',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0397480e-91a4-4b08-98ad-7d10e40aeaec/An_Exposé_on_the_Year_of_Perfection.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'Yu856SU04m4',
          Messages: ['3dmZqLPynnE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Y61kmnSYBbA',
      Status: 'Published',
      Description:
        'Gain deeper understanding from this exposé on the Year of Preparation as ministers expound with truths from God?s Word the impact of the message in their lives. The Man of God, Pastor Chris, gives light through the wisdom of God to nations, bringing to them God?s counsel to save them from the damnation coming to this world. He explains the rapture of the Church and the tribulation that will come to this world after the saints of God are gone to be with the Lord.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0397480e-91a4-4b08-98ad-7d10e40aeaec/An_Exposé_on_the_Year_of_Perfection.png',
    },
    {
      Categories: ['RXY0Q2L91_o'],
      Title: 'The Holy Spirit and Prayer',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bad9a602-22c9-4dde-b4e1-057c6013aa14/hs-n-prayer-pt1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: '7hj8i3LAqxw',
          Messages: ['GXKI7RDNPQQ', '6AGluIMd9IY'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['1_ihlhgF9CY', 'E6rfqd7xBYc'],
          SeasonId: '2QU3wvV_smc',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'EZS0QfwThYc',
      Description:
        'There’s no place in ministry for weakness or fear. The minister who has been called of God into the ministry has a certain kind of mentality: It is a mentality of courage. Find out from this teaching by Pastor Chris key things that you must do to be a successful minister of the gospel.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bad9a602-22c9-4dde-b4e1-057c6013aa14/hs-n-prayer-pt1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Image of the Heavenly',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/2e923c9f-b850-46cd-b925-4d4aa8a9a416/image_of_the_heavenly_pt1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'mdCwP8LvEkE',
          Messages: ['OjvSWQsRmys', 'ZohWYa7-cls'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '60ZZ2-DoRrU',
      Description:
        'Only those who have received the life and nature of God in their spirits can know God,  and this begins when you’re born again. There is power in our mouths,  and we’ve got to say it to make it work. In this special teaching,  Pastor Chris brings to light the nature and proficiency of the man in Christ,  and how transformation can occur in a man’s spirit through the revelation of God’s word.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/2e923c9f-b850-46cd-b925-4d4aa8a9a416/image_of_the_heavenly_pt1_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Increase In Grace',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6af3cc36-789b-4640-89f7-ea710e517853/Increase-in-Grace.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'gN5_It9MREA',
          Messages: ['alcNtz7oPY4'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'm16b--_sYCE',
      Status: 'Published',
      Description:
        '"Understand how to activate the divine influence of Grace that brings Acceptability,  Advantage,  Favour,  Joy,  Liberality,  Pleasures,  and Gifts into your life."',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6af3cc36-789b-4640-89f7-ea710e517853/Increase-in-Grace.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'The Benediction',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the-benediction.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'BvIkKcQ4zjg',
          Messages: ['EXYxgqy_L9Y'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'tEvENscssj8',
      Status: 'Published',
      Description:
        'The scriptures tell us in 2 Corinthians 13:14 about the grace of the Lord Jesus Christ, the love of God and the communion of the Holy Spirit, which is with us always. In this message excerpt by Pastor Chris Oyakhilome DSc, DD, you will understand the person of Jesus and his purpose in his death, burial and resurrection.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the-benediction.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'Heaven In You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5083b7ee-d521-4ef5-8ba2-f4c27be1ec2b/heaven_in_you_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['8t1H45sMP2Y'],
          SeasonId: 'tom4GiaplHA',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'nHIdY3--m3g',
      Description:
        'Discover how to live in two worlds and how to rule your body using faith-filled words. Learn how to communicate with heaven’s language in all you do and say as you bring to light in this world the life of heaven.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5083b7ee-d521-4ef5-8ba2-f4c27be1ec2b/heaven_in_you_240.png',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'Ikpere Nba-Nile Ekpere (Agba-nke Obodo Naijiria)',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/praying-for-the-nations-volume-2-igbo.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'LVvjYxFggcE',
          Messages: ['O6yqrt7GRH0'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'g1BicNM-fhg',
      Status: 'Published',
      Description:
        'Akwukwo Nso Siri na Ndi-Kolosi 4:2 “?anogidesinu ike n’ekpere-unu, namu-kwa-nu anya n’ime inye-ekele”. Agha di-egwu n’aga n’iri na uwa nile. Nzuko-Krist ga n’enwe nmeri site n’ike na-enweghi-atu nke di n’aha Onye-nwe-anyi Jisos. N’ozi a nke Onye-ozuzu-aturu Chris Oyakhilome DSc., DD., weputara, nke-akporo IKPERE MBA-NILE EKPERE (Agba-nke Obodo Naijiria), anyi na-etinye n’olu ike nke-enyere-anyi iji-hu na ewuliri ochicho-obi nke Onye-nwe-anyi ma nwe-kwa nmeri n’obodo Naijiria site n’ekpere.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/praying-for-the-nations-volume-2-igbo.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Purpose,  Plan and Timing',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/84a8a172-3f10-4e76-89db-fb168830bb69/purpose_plan_and_timing_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['Hli9kwKe4YY'],
          SeasonId: 'XW_3hKL5sC4',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'oq1gZiXU0II',
      Description:
        "Knowing God’s purpose for your life is not enough; you've got to follow His plan and time. Discover from this teaching three important factors that will help you experience tremendous growth and development in your life and in everything you do.",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/84a8a172-3f10-4e76-89db-fb168830bb69/purpose_plan_and_timing_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'A Man Sent from God ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6c582a62-e22f-4a73-b5ad-5f46221fcefd/a-man-sent-from-God.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'O1qLmW_y17o',
          Messages: ['52FplMj04kk'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '4rCgFDBzUzQ',
      Status: 'Published',
      Description:
        'Are you a burning and a shining light? If you burn and shine,  those who come around you will feel the heat.  The world belongs to that man or woman who burns and shines for the Lord Jesus. Learn from this classic message by Pastor Chris how to conquer your world.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6c582a62-e22f-4a73-b5ad-5f46221fcefd/a-man-sent-from-God.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'March 2017 - The Month of Insight',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fe43310d-48f0-4fb3-bec9-afc67fe53d78/March_2017_The_Month_of_Insight.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['AvQYcbcvruE'],
          SeasonId: 'nf7hQfB2Jkg',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'Nts0r1gNhPA',
      Description:
        '‘Insight’ is a clear,  deep and usually sudden understanding,  perception and penetration of a situation,  subject or mystery. Once you have the accurate knowledge of the Word,  nothing in this life will mystify or discomfit you. Experience immediate transformation,  as you watch and apply the principles of God’s Word as taught in this special teaching.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fe43310d-48f0-4fb3-bec9-afc67fe53d78/March_2017_The_Month_of_Insight.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'The Light of Life',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5b02e0ec-ac8f-4987-8800-b6ec18ad7071/the_light_of_life_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'aLaCybCoSPw',
          Messages: ['sVd5wOr6Hes'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'hdQ7sHQT3ys',
      Status: 'Published',
      Description:
        'The Word of God is light,  and that light lightens every darkness. There’re mysteries and secrets about life,  and the anointing of God’s Spirit has come upon us to guide us into the mysteries and secrets of God through the Word. Find out from this special teaching by Pastor Chris the purpose of the Word of God in your life.\n',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5b02e0ec-ac8f-4987-8800-b6ec18ad7071/the_light_of_life_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: '3 Kinds of Wisdom',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/3-kinds-of-wisdom.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'lMXhKrrnN-M',
          Messages: [
            'vjgFimfpBhw',
            'EYkxbA8ooWg',
            'pjgF-cH2zSA',
            'QXEdZUqKnts',
            'yFZqjKzIDf0',
            'npm5y8o54Gc',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'hhb5rqxoGnI',
      Description:
        'Why do many people in the world live unfulfilled lives? Can your organization or country depend on your productivity and excellence? Find out from this message by Pastor Chris Oyakhilome how develop your spirit to be productive and victorious every day. You’ll understand from this teaching how God brings you to the mindset of the righteous.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/3-kinds-of-wisdom.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'How to Chart Your Course of Greatness ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fb56eeee-2d9e-440a-a7f1-01accf1497ad/how-to-chart-your-course-pt-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'KOdWw-MIiWI',
          Messages: ['qd4jIQqFA4Y', 'fP0b-tpYxq0'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'YeXM4DVzLlY',
      Description:
        'Be inspired for a life of success and influence as Pastor Chris shares on seven salient steps crucial to embarking on your journey of greatness in this awe-inspiring message.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fb56eeee-2d9e-440a-a7f1-01accf1497ad/how-to-chart-your-course-pt-1.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Educating the Human Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1e9cd482-6ad2-4a8f-812b-cbb64b392a3e/educating-d-human-spirit.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'dW5-W_f1dXQ',
          Messages: ['ffbPwo_YNVA'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['Zrxk0lmcGNU', 'X2SUFJQnHc4'],
          SeasonId: 'YYH8O0VPOHI',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ppJO26Wzo6g',
      Description:
        'There are no limits to what you can achieve,  absolutely no restrictions to how high you can soar when you educate your spirit. You’ll be upgraded as Pastor Chris shows you how to function in realms of limitless success in this transformational message; Educating the Human Spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1e9cd482-6ad2-4a8f-812b-cbb64b392a3e/educating-d-human-spirit.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'AocoInCV3Qk'],
      Title: 'Authority in the Name of Jesus',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/AUTHORITY_IN_THE_NAME_OF_JESUS2.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['RWENP57H2Fo'],
          SeasonId: 'fJBK9EvKp7A',
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['yGLVWRGpDzg'],
          SeasonId: 'VrFlwYJREbU',
        },
        {
          Status: 'Published',
          Title: 'Portuguese',
          Messages: ['aOquAeqKGQ4'],
          SeasonId: '7rxVHOO5xnU',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['ptkrUWy_tO0'],
          SeasonId: 'S5j5oiSYJqw',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '281nvn7AsC0',
      Description:
        'There’s no room for doubt when you use the name of Jesus,  because God has set Him in the place of authority,  far above all rule and every title that can be conferred both in this world and in the world to come. Learn from this special teaching by Pastor Chris how to use the name of Jesus to effect changes in your life,  city and world.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/AUTHORITY_IN_THE_NAME_OF_JESUS2.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Growing Spiritually',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5a4d94b0-6b8e-4f56-958c-e378b276ecf9/Growing_Spiritually.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '_qwbcbaWnd4',
          Messages: ['Fdh2XhaUYI0', '7Yh34oumIhE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'c0J55MSlOG4',
      Description:
        'Did you know that your spirit came from God’s word? As spirit beings,  you are the offspring of God’s word and growth is of utmost necessity for you to live a transcendent Christian life. Find out from this timely message by Pastor Chris how to grow spiritually.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5a4d94b0-6b8e-4f56-958c-e378b276ecf9/Growing_Spiritually.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'AocoInCV3Qk'],
      Title: '4 Things Christ is Made Unto Us',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/4_THINGS_CHRIST_IS_MADE_UNTO_US.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['gh1NGSBELNA', '68lao1Oo2I0'],
          SeasonId: 'VOOrsqPW7VE',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'qC7DPzTkaIg',
      Description:
        'Your comprehension of all that Christ is to you will put you above in life. It will distinguish you and give you extraordinary amplitude of understanding. Enjoy the amazing revelations of the man in Christ and how distinguished we are as you get to know four essential things Christ is made unto you in this message by Pastor Chris Oyakhilome.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/4_THINGS_CHRIST_IS_MADE_UNTO_US.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Sin,  Forgiveness and Righteousness',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e4dde2c7-32eb-4cfd-bcd0-ff03d1405cc1/sin_forgiveness_righteousness_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'jFaRPEtnqu0',
          Messages: ['9cauRpOUkos'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['5xn5BP7KTgI'],
          SeasonId: 'LmAMh2PBuXc',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'GN-KE6dpGwg',
      Description:
        'Do you know that the blood of Jesus Christ cleanses you from all sin? And the righteousness of God that qualifies you to stand in His presence without guilt or condemnation has been imparted to your spirit. Learn from this insightful teaching by Pastor Chris how to walk in the perfect will of the Father in every area of your life.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e4dde2c7-32eb-4cfd-bcd0-ff03d1405cc1/sin_forgiveness_righteousness_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'The Integrity of the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/cbccb101-e8d3-4f6e-a2d7-31108671f8cc/the-integrity-of-the-word-vol-1-part-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['LSO1E_FKzHw', 'NdjBK4nXyMo', '_YXXDQKYAU0'],
          SeasonId: 'AQEALURPgeY',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['Szgi9R2vsSQ', 'QV4x9Q4DbP8', 'CfxgTz2zasI'],
          SeasonId: 'cMB4Zh5l9LM',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['J6U-0N-IMVM', 'bTyxJXcwIV4', 'h_B61bfAvFI'],
          SeasonId: 'JGz1GuZRFtw',
        },
        {
          Status: 'Published',
          Title: 'Volume 4',
          Messages: ['PsR7nyvoatg', 'ZCiKW_hgmMQ', 'BUi1IIAh0o0'],
          SeasonId: 'wQJvtqkjmjM',
        },
        {
          Status: 'Published',
          Title: 'Volume 5',
          Messages: ['MCz3-u8AA4E', 'gouvB7BuboQ'],
          SeasonId: 'wpX04KITP48',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'B2mSg-3MsAg',
      Description:
        'The most powerful force in the universe is the force of words. A clear understanding that the word of God is absolutely dependable and able to cause circumstances to align with God’s perfect will for you is what you require to live the authentic Christian life. As you watch this special message; ‘The Integrity of the word’ let your faith come alive and start producing results as you learn and apply the practical principles of believing and declaring God’s word. ',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/cbccb101-e8d3-4f6e-a2d7-31108671f8cc/the-integrity-of-the-word-vol-1-part-1.png',
    },
    {
      Categories: ['ppTyq4rpOoY'],
      Title: 'Chronicles of Prophecy',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/thumbs/chronicles_of_Prophecy.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Season 2',
          SeasonId: 'zrygt7_UoMc',
          Messages: [
            'ieLOpyIvCIQ',
            'DtOC14lsUzo',
            '2BulvpcX4BM',
            'fPf9HLf-RYc',
            'Q9u9AcqNhmM',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'KrnPc2PyZkQ',
      Description:
        'Watch the post news analysis of Your LoveWorld Season 2 Phase 1 Day 1 where the Chronicles of Prophecy news crew give detailed analysis to salient points made by the man of God, Pastor Chris, regarding vaccinations and the fraud among the nations with respect to pseudo-science.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/Categories/thumbs/chronicles_of_Prophecy.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak', 'AocoInCV3Qk'],
      Title: 'Spiritual Warfare',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/SPIRITUAL_WARFARE_SERIES.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Keys Of The Kingdom',
          SeasonId: '6FKBBeBPkRU',
          Messages: ['tMJjEnJm3Z4', 'FIg1lCUbUpI', 'MQ4F9_VnSAI', 'q7BzsboAzkI'],
        },
        {
          Status: 'Published',
          Title: 'Understanding Demonic Activity',
          Messages: ['oNkCt0TrNgw'],
          SeasonId: 'gcVldN_1yIc',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'n4FS-gbwGko',
      Description:
        'We are the body of Christ and members in particular. Get to know how to avert satanic strategies and manipulations,  which tend to sway us away from the Presence of the Lord,  His word and fellowship with other Christians. You’ll also find out from this teaching by Pastor Chris how to effect the change you desire in your church,  family,  or finances through prayer.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/SPIRITUAL_WARFARE_SERIES.jpg',
    },
    {
      Categories: [],
      Title: 'May 2023 – The Month of Victory',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/may-2023-the-month-of-victory.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'zVBasS0VK-E',
          Messages: ['oUKB7XUJhY0'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ZidRpepjGs0',
      Description:
        'Did you know that the Lord Jesus Christ has given all authority in heaven and earth to the Church, which is his body? The Church of Jesus Christ has been given divine authority to judge the nations. As you watch this excerpt from the Global Service with Pastor Chris Oyakhilome DSc, DSc, DD, you will understand your role in the body of Christ; how to use your authority in Christ and walk in victory over the nations, peoples and circumstances of life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/may-2023-the-month-of-victory.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Impact of the Holy Spirit on the Human Body',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the-impact-of-the-holy-spirit-on-the-human-body.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'mn0sBRhs-sE',
          Messages: ['uvDbijHrRU4', 'hBO3DOtkCg4'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'YN7PlWt6ibk',
      Description:
        'Did you know that faith jumps, but fear hesitates? Christianity is a relationship not a religion.  The more we behold the Word, there more we are transformed. You are the temple of the Living God; He dwells in you. Find out from this message by Pastor Chris Oyakhilome the glory of the Holy Spirit in a human person.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the-impact-of-the-holy-spirit-on-the-human-body.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'The Human Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f6a9dfdf-dcf8-4c70-9358-b26295bb93b1/the_human_spirit_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['5GSahtglCNg', 'R4Gwn8bbuOA', 'pcjGZ2OZ2wY'],
          SeasonId: 'D4aUaOE_DQY',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'qObgpYrx-Zw',
      Description:
        'Discover how to reprogram your inward man for victory and success continually through the renewing power of God’s Word. Learn how to enjoy spiritual things,  and how to exercise control in your spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f6a9dfdf-dcf8-4c70-9358-b26295bb93b1/the_human_spirit_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0', 'AocoInCV3Qk'],
      Title: 'The Church Yesterday, Today and Forever',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_CHURCH_YESTERDAY,_TODAY_AND_FOREVER.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'lwMQGm0FBc4',
          Messages: ['fq_NZDv6WPI'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'WfrzLXsp8tQ',
      Description:
        '…Upon this rock I will build my church; and the gates of hell shall not prevail against it (Matthew 16:18).  This classic teaching focuses on the growth and metamorphoses of the church. The revelations and illustrations herein are divinely designed to enrich your knowledge of the Spirit’s out-breathings for the church. Get an understanding of the glorious transition of the Church yesterday,  today and forever.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_CHURCH_YESTERDAY,_TODAY_AND_FOREVER.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Why Did Jesus Come?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/why-did-jesus-come.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'yUkZjcAeQ8A',
          Messages: ['7rWuB8UgUZQ'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: '03SjBadZj_g',
      Status: 'Published',
      Description:
        'Do you know why Jesus came? The Bible says in 1 John 3:8, “For this purpose the Son of God was manifested, that he might destroy the works of the devil. Find out from this message by Pastor Chris Oyakhilome DSc, DD, why Jesus came and the meaning of ‘Son of God’.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/why-did-jesus-come.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'The Year of the Gathering Clouds',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the_year_of_the_gathering_clouds_new+(200+x+200).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'rtIswh0zQz8',
          Messages: [
            'bBcTIuU7dSc',
            'E2tnkuNG_OU',
            'nfIF0UYQ_8I',
            'OQZ3iDDzJds',
            '6dvtiFmS8mk',
            '3bdV-U5hwsY',
            'IMA2_cEPjNM',
            'nvkUUrgCCHo',
            'vBHOFgp1Gfo',
            'ejaC1TqpXsg',
            'EzNoHefiJPg',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'DWSfc8AvFkc',
      Description:
        ' “Ask ye of the Lord rain in the time of the latter rain. And the Lord shall make bright clouds, and give them showers of rain to every one grass in the field (Zechariah 10:1).” This year, the clouds of prayers, faith and expectations will be full and begin to empty themselves on the earth. What follows the latter rain is the coming of the Lord. For the latter rain is the rain of harvest. In this insightful message, Pastor Chris Oyakhilome DSc, DD, explained the different gathering clouds and their symbolisms to the Holy Spirit, the happenings in the world, and the rapture of the Church.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/the_year_of_the_gathering_clouds_new+(200+x+200).jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Auto-Edification by Glossolalia',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/227b600d-6e69-455c-bcd1-004b23b28cc2/Auto-Edification-by-Glossolalia.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'm1khtREjtg0',
          Messages: ['k8qyFNAR1-w'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '7FrG5-QsoZA',
      Status: 'Published',
      Description:
        'Build yourself up on your most holy faith by praying in the spirit. As you pray,  you will be edified,  strengthened,  and invigorated with power by the Holy Spirit who lives in you. Relive the moments from this special teaching by Pastor Chris on Auto-Edification.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/227b600d-6e69-455c-bcd1-004b23b28cc2/Auto-Edification-by-Glossolalia.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'RXY0Q2L91_o', 'N5y75pdx8k0', 'AocoInCV3Qk'],
      Title: '5 Lessons on Prayer',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/5_LESSONS_ON_PRAYER.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'EPVcrmtcRNE',
          Messages: ['Mdckl5F4kYg'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'jOYjshYbJiQ',
      Description:
        'Prayer in the New Testament is partnership with God. It is executing His divine will and purpose on earth as it is in heaven. When you understand the gift of righteousness, you walk in faith; and the work of righteousness produces peace. The effect of righteousness produces faith in our spirits. Learn from this insightful teaching by Pastor Chris Oyakhilome DSc, DD, 5 important lessons to recognize in prayer.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/5_LESSONS_ON_PRAYER.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', '4BgKISdOY9s'],
      Title: '7 Inspirations for Success',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/7_inspirations_for_success_(200+x+200).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '2LiNjkPeQqE',
          Messages: ['FVsqyVHMgHc'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'D7KZNctt1r0',
      Status: 'Published',
      Description:
        'Did you know that the Word of God in your spirit has programmed you for success, victory and prosperity? The Word of God is living and active. Learn from this timely teaching by Pastor Chris Oyakhilome DSc, DD how to grow in all things, living in boldness and walking in the power of the Holy Spirit.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/7_inspirations_for_success_(200+x+200).jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'October 2018 - The Month of Instruction',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/69bf73b2-dd92-4f53-9db0-4db63a1b0911/October-2018-the-month-of-instruction(new).png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'q46eNazrpaI',
          Messages: ['Vi0XpYhjfKo'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'PNn6O6yVZZw',
      Status: 'Published',
      Description:
        'Do you know that one little instruction the Lord gives you can lead to big things in your life? You will understand from this teaching by Pastor Chris the definition of instruction and how to walk in the light of the word for the month.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/69bf73b2-dd92-4f53-9db0-4db63a1b0911/October-2018-the-month-of-instruction(new).png',
    },
    {
      Categories: ['RXY0Q2L91_o'],
      Title: 'The Communion Of The Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fba4d3f0-ea81-4e21-ae35-9b59d28db3ca/the_communion_of_the_Spirit_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['rikH7503x04', '_B725YR5lkU'],
          SeasonId: 'arRsEX0IqyE',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['FxMewXQELpQ', '_xPLVv-GCiA'],
          SeasonId: 'XHJjCn-rVVE',
        },
        {
          Status: 'Published',
          Title: 'Italian',
          Messages: ['9jue78X_JZA'],
          SeasonId: 'ajHjECfNTYM',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'vpol9Zs0NIA',
      Description:
        'Learn how to enjoy and take advantage of your fellowship with the Holy Spirit to become a Master and a Winner in anything! The Holy Spirit in your life is the key to the Extra ordinary; He is the key to Absolute and Unchanging Success. Learn how to use this key to remove limitations,  gain the Mastery and succeed everyday.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fba4d3f0-ea81-4e21-ae35-9b59d28db3ca/the_communion_of_the_Spirit_240.png',
    },
    {
      Categories: ['sTniib-wePM'],
      Title: 'Praying for the Nations',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/praying-for-the-nations.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'eDihKKM1U8w',
          Messages: ['iTGJSQr_Kyg'],
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['NAtVQX8HGCw', 'Bsys-UloFgM'],
          SeasonId: 'M998ba3a6N4',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Bcru2oLkUW0',
      Description:
        'What would our world be like if we all took our place in praying for the nations of the world according to God’s will? In this timely audio compilation, Pastor Chris Leads in sessions of prayer for the nations of the world.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/praying-for-the-nations.jpg',
    },
    {
      Categories: ['N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'How to Live a Worry-Free Life',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c9c921d1-bfbf-4702-9cac-dac6dbe893d4/how_to_live_a_worry_free_life_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['jikFgN-6ZGg'],
          SeasonId: 'z229PzhGJaw',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'XNqzSj55F7Y',
      Description:
        'What kind of life would you like to live; a happy,  successful life,  or a life full of worries? In the realm of the spirit,  the Word is all that matters. Discover from this classic teaching by Pastor Chris how to speak forth the Word that produces the results that you desire in your life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c9c921d1-bfbf-4702-9cac-dac6dbe893d4/how_to_live_a_worry_free_life_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Children of the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/20c362b8-99a6-4055-b008-18c39adf52ea/children_of_the_word.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'yhywRO0Hmwc',
          Messages: ['rh_QnnNPpcQ'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'B24qeD9BJgc',
      Status: 'Published',
      Description:
        'In this message,  Pastor Chris establishes the character and nature of the Word as revealed in the Bible. He then declares to us that we are born of the Word and therefore must display the qualities of the Word as established. The Word must control our perspective in life; every thought,  idea and notion must be brought under the control of God’s Word. Get yourself placed in a position of advantage as you listen to this message and recognize our position in this life as Children of the Word.\n',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/20c362b8-99a6-4055-b008-18c39adf52ea/children_of_the_word.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o'],
      Title: 'Be Filled With The Spirit',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c72028e1-1400-49e5-a645-6ea21aac26e7/be_filled_with_the_spirit_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['Ksw-vN9idfg', 'jCIsk5iBfVY'],
          SeasonId: '39P5UCBxAs8',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'LSyzBiDLJtE',
      Description:
        'Do you want to have a life full of an unending stream of the supernatural? Discover from this teaching how to be filled with the spirit continually.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c72028e1-1400-49e5-a645-6ea21aac26e7/be_filled_with_the_spirit_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Origin, Purpose and Future',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/46d47b7f-2bd3-4dfe-9025-7950d54c6906/Origin-Purpose-and-Future.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'JwzyGcNIKTA',
          Messages: ['MS9ocPwaoUQ'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['1jm2XaExHS0'],
          SeasonId: 'Wx-cXpJ50m0',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Ugpu2CLoD_c',
      Description:
        'There are so many people in the world today living like vagabonds,  going through life without an anchor,  a purpose for living or a sense of direction but when you come into Christ,  you become a child of God and a member of His body. To function excellently and enjoy all the benefits of this divine kingdom,  you must know your Origin,  understand your Purpose and live in preparation of your glorious future in Christ.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/46d47b7f-2bd3-4dfe-9025-7950d54c6906/Origin-Purpose-and-Future.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o'],
      Title: 'You in Christ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/aac1d593-ab3f-4598-91f6-95cc15f66d16/You-in-Christ.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '4HNufD3Ft-Q',
          Messages: ['e7nTLu2_wqw'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['goKwJc-51B4'],
          SeasonId: 'BjWG7xgCItU',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'TF7HsgD_Wxw',
      Description:
        'The person who is in Christ is the workmanship of God recreated in Christ Jesus unto good works. Learn from this message by Pastor Chris how a Christian becomes one with Christ through baptism in the Holy Spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/aac1d593-ab3f-4598-91f6-95cc15f66d16/You-in-Christ.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'September 2021 - The Month of Visualization',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/september_2021_the_month_of_visualization1.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '-GUEir4uGB4',
          Messages: ['aN4zRtrJKj8'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'HJopUq2-t-E',
      Status: 'Published',
      Description:
        'The month of September was declared to be the month of Visualization. Visualization is the design of your life and circumstances with thoughts and words. Keep our eyes away from those things that work against your construction of your life and circumstances. We are creators, with inherent powers to change our lives and create the future God desires for us. Learn from this message by Pastor Chris Oyakhilome how to enrich your mind and spirit win seeing the picture of what God has said about you in His Word.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/september_2021_the_month_of_visualization1.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: 'Year of the Supernatural',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f32fe36c-1baa-4e2b-bec3-ef5f41a60f97/Year_of_the_Supernatural.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'nTszCB30uzY',
          Messages: ['n39Y1t4Gp7Q'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['LLWf_C7eHQI'],
          SeasonId: 'oPuyD4nD-TE',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'KOoAb_OzU3Y',
      Description:
        '2018 has been declared to be the year of the Supernatural. The Lord has commanded his blessing upon you and you shall bring forth fruit. Find out from this special teaching by Pastor Chris how to live in the Supernatural.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f32fe36c-1baa-4e2b-bec3-ef5f41a60f97/Year_of_the_Supernatural.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Consummate Man',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/daf10115-4df7-44fa-864c-2c1429966ee3/consummate_man_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'YKLqk7BOVcA',
          Messages: ['Lyz7636nmr4', 'rZJF7-ODTxY', 'IggcmD2T9ZI'],
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'ftCItZ7PG2k',
      Description:
        "Understand that God''s purpose for you is to be perfect and entire,  wanting nothing. Discover how to live the good life that God has planned for you.\n",
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/daf10115-4df7-44fa-864c-2c1429966ee3/consummate_man_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Glory of the Word (An introduction)',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5b470361-dc3d-4933-b639-d957ff1cb965/the-glory-of-the-word.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'nole2b3vbIc',
          Messages: ['-fkRfP_pT6w'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'bmwqPAVBD78',
      Status: 'Published',
      Description:
        'Do you know that you glow more and more through the full knowledge of the word of God that you received in your spirit? The more you know the Word,  the more the glory is revealed in your life. Find out from this special teaching by Pastor Chris the glory inside you and how to use the Word as a building material for your spirit.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5b470361-dc3d-4933-b639-d957ff1cb965/the-glory-of-the-word.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Higher Life Conference',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6afd8093-c92e-468d-9473-e3bfa8a6c68d/hlc_can_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['h2Bp83Kwk00', 'boTc8PyjI50'],
          SeasonId: 'eFBQ6KNjUks',
        },
        {
          Status: '',
          Title: 'Volume 2',
          Messages: ['ehj0Lch3blQ', '2E47Xtg8WT4'],
          SeasonId: 'inAjBfEyJvo',
        },
        {
          Status: '',
          Title: 'Volume 3',
          Messages: ['KBWbEIEczuE', 'Y3eDU_6Uvyg', 'juUVGtePQx8'],
          SeasonId: 'c5VdXB3WrCs',
        },
        {
          Status: 'Published',
          Title: 'Volume 4',
          Messages: ['UjDntftKQyY', 'tgtfp54uqvA'],
          SeasonId: '0BvquzaY9vs',
        },
        {
          Status: 'Published',
          Title: 'Volume 5',
          Messages: ['j2KZBueRT84', 'VYtMhi5QSmk', 'buTxZykiC2M'],
          SeasonId: 'RWzM6I-vJgM',
        },
        {
          Status: '',
          Title: 'Volume 6',
          Messages: ['IkUktA4Z9cU', 'JG6SnERv2S0', '04RYLQ3fXow'],
          SeasonId: '2BkYvYTEylk',
        },
        {
          Status: '',
          Title: 'Volume 7',
          Messages: ['OHOOWSXnBFk', 'ETj1-lN5rb8', 'GAx3MvDOzoY'],
          SeasonId: 'bS6SCuWqcwo',
        },
        {
          Status: '',
          Title: 'Volume 8',
          Messages: ['R20mvAkM99o', '6U9l_PQWgUM', 'XaGmhfYG3qE'],
          SeasonId: 'AHp3irva6R0',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'YGCwCrIbjs4',
      Description:
        'Learn how to live the higher life in Christ that will make you triumphant over lack,  want,  sickness,  and disease. Understand how to be the best of you for the Lord.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6afd8093-c92e-468d-9473-e3bfa8a6c68d/hlc_can_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'February 2019 - The Month of Walking in the Light',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8d1b6c2f-a6f6-4dfb-9fd5-9a694748f4cc/February-2019-the-month-of-walking-in-the-light.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '9KNQUkObTF0',
          Messages: ['nZlJXiwb02c'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '6HqnbNmkfIU',
      Status: 'Published',
      Description:
        'February has been declared to be the month of walking in the light of God’s word. Find out more on how to live a victorious life as you watch this video by Pastor Chris Oyakhilome.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/8d1b6c2f-a6f6-4dfb-9fd5-9a694748f4cc/February-2019-the-month-of-walking-in-the-light.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Attitude',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c14edce8-29f9-4cdb-a6c5-f743b37f9eb1/attitude_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'zkIeS0q80hw',
          Messages: ['PK-2ZuNRIfU'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'FtYJpz4TR0I',
      Status: 'Published',
      Description:
        'Learn in this message how your attitude can define your value and fulfillment. You will also learn how to walk right with God in humility.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c14edce8-29f9-4cdb-a6c5-f743b37f9eb1/attitude_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Live Your Best',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4998076e-35ff-4fca-a22b-46a152aaa7f6/live_your_best_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['JpvT5BwwHVg'],
          SeasonId: 'uBHJmP20u58',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'U7wVKypLyDo',
      Description:
        'Do you know that God created you to be an absolute success in life? Learn from this special teaching by Pastor Chris Oyakhilome three important principles necessary for you to live a happy and successful life.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/4998076e-35ff-4fca-a22b-46a152aaa7f6/live_your_best_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'AocoInCV3Qk'],
      Title: 'Authority For Success',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/AUTHORITY_FOR_SUCCESS.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'RPUjfDBb7lw',
          Messages: ['d_EEI_kgGQE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'FOPUa54JUG0',
      Description:
        'Did you know that the authority of Jesus is far higher than the authority of any nation and its leaders, not only in this age, but in the ages to come? In Christianity, God has placed all things under the Church – the body of Christ and has given Jesus Christ to be the head. In this compelling video by Pastor Chris Oyakhilome DSc, DSc, DD, you’ll understand the authority vested in the name of Jesus and how that name in effecting changes in your world.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/AUTHORITY_FOR_SUCCESS.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'For This Cause',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5c8bb32f-1f48-4fb0-8534-5ccaa3cb569b/for-this-cause.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '-FLiBvURIuY',
          Messages: ['jkmlWEtDgGk', 'RVPCaNcjCxQ'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'rrDGr5vo6QQ',
      Description:
        'Do you know that the Lord’s body was broken for your sake,  so that you may never walk in sickness,  weakness or condemnation? Learn from this special teaching by Pastor Chris deep truths about the bread and the blood; and how we participate in the body of Christ.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/5c8bb32f-1f48-4fb0-8534-5ccaa3cb569b/for-this-cause.png',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: "International Partners' Conference 2014",
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3ffc4915-df71-4477-88d5-09c49e66b67f/ipc-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'gMCj93HKAHY',
          Messages: ['L3qmdYlSHro', '3Sj6-M9q4kM', 'Cq17E5FTt5w'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'mnwo2Y2S1yE',
      Description:
        'When there is a need to ride above the circumstances of life,  then the anointing upon your life must be increased. Position yourself for increase and get ready to swim in the ocean of grace,  as you get on with Pastor Chris in this special message.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/3ffc4915-df71-4477-88d5-09c49e66b67f/ipc-1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Christian Consecration',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/84b5ed04-ef4b-4b8a-933c-bd02b6e865c8/christian_consecration_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '7JQK4zMcVb4',
          Messages: ['_ysAZH65cbk', '5XOhN0tVrEc'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'c4H1MAgqcxI',
      Description:
        'Discover how to enjoy your rights as a new creation reality. In this expose,  you’ll learn who the Christian is and what rights he’s been given and how to function by God’s principles and live a life of unending victories and testimonies.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/84b5ed04-ef4b-4b8a-933c-bd02b6e865c8/christian_consecration_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Year of Perfection',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/cbd84f3a-0818-42b6-a1f6-c8b429726521/The-Year-of-Perfection.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'Jn-1M3zIY20',
          Messages: ['OR-EjxhB7mI'],
        },
        {
          Status: 'Published',
          Title: 'Study',
          Messages: [
            'yidwaGqK0dU',
            'tkMoBqmxSvs',
            'zy0WSPFPdZw',
            '11KckE8eQ3M',
            'Hy_lx97nuYU',
          ],
          SeasonId: '9JzPs5JtP-k',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ap3Q8HRgNrA',
      Description:
        'Did you know that the Lord demands and expects perfection for every of his children? As you listen to this insightful teaching by Pastor Chris Oyakhilome,  you will activate the consciousness of perfection in your life and function in alignment,  completeness,  excellence,  fruition of all your set goals in the year.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/cbd84f3a-0818-42b6-a1f6-c8b429726521/The-Year-of-Perfection.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'November 2022 – The Month of Insight',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/november-2022-the-month-of-insight.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'TYwiOU97-1s',
          Messages: ['tiYbHksw6mQ'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'aoNquEvBxOQ',
      Status: 'Published',
      Description:
        'The scriptures unveil us in Ephesians 1:17 AMPC the Holy Spirit’s desire for the Church of Jesus Christ: You have been granted insight into the mysteries and secrets of God. Throughout this month and beyond, the Holy Spirit will lead you in a very special way, bringing you understanding like you never thought possible as you fellowship with Him.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/november-2022-the-month-of-insight.jpg',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Faith to Receive',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f31a768e-62f6-4419-aa43-fe2c5673b0e6/faith_to_receive_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'XN20JW4TJJs',
          Messages: ['YmFKmkoxLVg'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '5puBfI00mxQ',
      Description:
        'Learn how to receive healing for your body. Discover how to activate the anointing for your healing miracle.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f31a768e-62f6-4419-aa43-fe2c5673b0e6/faith_to_receive_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak', 'sTniib-wePM'],
      Title: 'April 2022 – The Month of Watching and Praying',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/April_2022_the_month_of_watching_and_praying_(200+x+200).jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'kLAWq-dX9s4',
          Messages: [],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'QvUwB4jsKkI',
      Status: 'Published',
      Description:
        'The scriptures admonish in Matthew 26:41, “Watch and pray, so that ye enter no into temptation…” God wants us to be prayerful and to give attention to His word. You’ll learn from this teaching by Pastor Chris Oyakhilome DSc, DD that the more knowledge of the Word of God you know, the more you can interpret the circumstances and happenings in your world. Get to know the three important things the Lord wants you to pay attention to this month as you watch this video.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/April_2022_the_month_of_watching_and_praying_(200+x+200).jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Excellence',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/40a88eba-262f-474a-ab68-8e80ad55a509/excellence_pt1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['bkJe8WjX2vA', '-nXHoNwH8t8'],
          SeasonId: 'zJQR0-v0LZ0',
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['uoEUvKNWuBA', 'uPhw-9nzYqA'],
          SeasonId: '2kioDzB3p1s',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '8JlQYGhBhJo',
      Description:
        'A man of understanding can be guided in the way of wisdom. Learn from this teaching how to instill reliability into your character for your next level of excellence.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/40a88eba-262f-474a-ab68-8e80ad55a509/excellence_pt1_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'sE72qV3sqJ0'],
      Title: 'The Year of Greatness',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ff779936-7c0b-4728-9fe7-dda489e53a0a/yr_of_greatness_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['rQz-UEd80qo', 'uZkATpdUjZE'],
          SeasonId: 'zpzug9M0-Fs',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'XrZiqiSt-Wk',
      Description:
        'A little one shall become a thousand,  and a small one,  a strong nation. This will characterize your growth throughout this year,  because you’re the seed of Abraham. Find out from this special teaching by Pastor Chris how to walk in greatness every day of your life.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/ff779936-7c0b-4728-9fe7-dda489e53a0a/yr_of_greatness_240.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'The Call of Blessings',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/a2a124a8-352d-4922-a1da-40101b0d517a/The_Call_of_Blessings.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'c6rCp82dCZ0',
          Messages: ['uNcMSrreRaU'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'VogsGRr5cF4',
      Status: 'Published',
      Description:
        'Did you know that you can only bless the man or woman that’s already blessed? Everything about your life is under the control of heaven when you’re born again; and you have become a part of heaven. Find out from this special teaching by Pastor Chris Oyakhilome how to walk in the Blessings of God for your life.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/a2a124a8-352d-4922-a1da-40101b0d517a/The_Call_of_Blessings.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Facts and Foundations',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f7596b80-425b-4cec-88b4-96467d84d428/facts-and-foundation-pt-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'sAk5xBbb9HI',
          Messages: ['I4w4Qzyso_M'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '3QNL80QYJQQ',
      Description:
        'There’s more to your life as a Christian than you may have explored so far,  and this timely message,  “Facts and Foundations, ” is set to usher you into a total new realm of knowledge that will inspire greater victories in your life. Lay hold on the convictions that form the bedrock of your faith in Christ as Pastor Chris expounds clear,  scripture-based answers to these questions: Who is Jesus Christ? What did He come to do? Who has He made you? These are the facts and foundations upon which a successful and fulfilled Christian life is based.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/f7596b80-425b-4cec-88b4-96467d84d428/facts-and-foundation-pt-1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'He Gave Us His Glory',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/he-gave-us-his-glory.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '47u-5YaPSw8',
          Messages: ['sB5a0oh5X3M'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'mZ0oGobE4dE',
      Status: 'Published',
      Description:
        'Did you know that it was always God’s plan to share His glory with human beings? The scriptures in Isaiah 42:8, referred to the Lord not sharing His glory with idols. In this message by Pastor Chris Oyakhilome DSc, DD, you’ll understand God’s plan for your life and how to fulfill it.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/he-gave-us-his-glory.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'October 2017 - The Month of Blessings',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7459cb0f-a663-46fe-af90-a9919290c4f2/October-2017-the-month-of-blessings-new.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'JMQiqTrSi7w',
          Messages: ['ldP81ZyZqmo'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '6cTaC-OwKso',
      Status: 'Published',
      Description:
        '‘And God blessed them,  and God said unto them,  be fruitful,  and multiply,  and replenish the earth,  and subdue it:..’ (Genesis 1:28). You are blessed and called to be a blessing. Get a deeper understanding of your blessings in Christ and live everyday happy,  healthy and successful as you listen to this timely teaching by Pastor Chris.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7459cb0f-a663-46fe-af90-a9919290c4f2/October-2017-the-month-of-blessings-new.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'July 2018 - The Month of Laughter',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bd5ba796-ade6-4c04-aec2-c60fd73ae037/3A3A4998.JPG',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '5p8Htu2uMcQ',
          Messages: ['-7R09ARFo_M'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'rQUaZNFWAuU',
      Status: 'Published',
      Description:
        'The month of July has been declared to be the month of laughter. Get ready for sudden blessings,  restorations and open doors. Find out what the Spirit of God has declared concerning your life for this month as you listen to this excerpt from the July 2018 Global Communion Service with Pastor Chris.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/bd5ba796-ade6-4c04-aec2-c60fd73ae037/3A3A4998.JPG',
    },
    {
      Categories: ['ZtPhUh6bsak', 'N5y75pdx8k0'],
      Title: 'The Year of Preparation',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f8685f0-c438-40b6-943e-fd62a5e9ee92/The_Year_of_Preparation.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'TcWDFx1VeO0',
          Messages: ['WcFG2LG3BWg'],
        },
        {
          Status: 'Published',
          Title: 'Dutch',
          Messages: ['99ttEH6z_2Q'],
          SeasonId: '1WLcU5L27ss',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'GLAhdlUnklA',
      Description:
        'In this message by Pastor Chris Oyakhilome DSc, DD, you will learn salient truths about the coming of the Lord and your role in preparing for the house of God and the spread of the gospel into every nation. Get to know the 4 cardinal points that will prepare you for an ever victorious life in the Word.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/1f8685f0-c438-40b6-943e-fd62a5e9ee92/The_Year_of_Preparation.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Faith,  Hope,  And Love',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7b675297-e15b-4cd8-a229-a79abfba5033/faith_hope_and_love_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'AlaL_r4z3F0',
          Messages: ['-iXMQ_WhRRw'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'C5Si1Fmw9Tw',
      Status: 'Published',
      Description:
        'Learn how to apply these three creative principles for victorious living and catapult yourself into a new level of relationship with God and those you relate with everyday.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/7b675297-e15b-4cd8-a229-a79abfba5033/faith_hope_and_love_240.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: '2023 – The Year of the Prolific Church',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/2023-the-year-of-the-prolific-church.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'Jdji2YrSqRc',
          Messages: ['kyehlCQocq4'],
        },
        {
          Status: 'Published',
          Title: 'Mandarin',
          Messages: ['F7wWBn5LKKo'],
          SeasonId: 'Tbfu1EwejsQ',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'uJN1V1uBnd4',
      Description:
        'God’s plan is to raise a kingdom of royal priests, as the scriptures said in 1 Peter 2:9, “But you are a chosen generation a royal priesthood, a holy nation, His own special people, that you may proclaim the praises of Him who called you out of darkness into his marvelous light.” His desire is for His Church to function as a kingdom in the earth not as an organization. This year, the Church is spreading throughout the earth unhindered by the power of the Holy Spirit, being prolific everywhere in an outstanding way. You’ll learn from this timely message by Pastor Chris Oyakhilome DSc, DD, 3 cardinal things to prioritize this year while walking in supernatural abundance and multiplication on every side.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/2023-the-year-of-the-prolific-church.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'Who is the Spirit?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/who-is-the-spirit.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'onI151StAo4',
          Messages: ['V5AFwX0Y7uM'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'PPrW6cvG5dg',
      Status: 'Published',
      Description:
        'Why do many people seek power after being born again? Why do many represent the Spirit with the symbol of a Dove? The Bible tells us the Spirit descended in bodily shape like a Dove descends. The baptism of the Spirit is not a second experience for the Christian. When you are born again, that is the baptism of the Spirit.  Understand from this message who the Christian is and the ministry of the Holy Spirit in the life of a Christian.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/who-is-the-spirit.jpg',
    },
    {
      Categories: ['sE72qV3sqJ0', 'N5y75pdx8k0', 'RXY0Q2L91_o'],
      Title: 'What is Ministry?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/479e3f5f-c731-44b8-b19e-f59c5b9f08f4/what-is-ministry-pt-1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'r9GH2dahcMg',
          Messages: ['hD-wZ2CVvO0', 'x3_iu59h9jc'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'aK_Snlvxhy4',
      Description:
        'Ministry is not the set up or organization of a church. Ministry is not the administration of a congregation. Ministry is about the outpouring of the life of the Spirit of God that is in you. In this teaching,  Pastor Chris explains deep truths on how a minister of God can release words that have Spirit and life.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/479e3f5f-c731-44b8-b19e-f59c5b9f08f4/what-is-ministry-pt-1.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', '6EKZPdy84W0'],
      Title: 'The Spirit The Power and The Anointing',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e60a9b47-4394-41ed-90d1-9e0270247ca5/the-spirit-the-power-and-the-anointing-vol1pt1.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['bnc2DrbgvBo', 'wR101t47nsA'],
          SeasonId: 'WKZqKbwugrA',
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['boCEoPOO0dw'],
          SeasonId: 'n-JAlkcUPRQ',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['lY5aeV7f8aM', 'qllW6XupZDE'],
          SeasonId: 'rGFo3-UoUEM',
        },
      ],
      Author: ' DesW',
      SeriesId: 'sTt9HUVxzA0',
      Description:
        'You shall receive power after the Holy Ghost comes upon you. That means you have been appointed by God to preach the gospel,  heal the sick and to set the oppressed free. Learn from this special teaching by Pastor Chris what the anointing is and the relationship between the anointing,  the appointment and the power of the Holy Spirit.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e60a9b47-4394-41ed-90d1-9e0270247ca5/the-spirit-the-power-and-the-anointing-vol1pt1.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'August 2017 - The Month of the Shining Light',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0d77ba8f-0b15-4c60-bc56-490da233a358/the-month-of-the-shining-light.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'M4AOH4iFqQk',
          Messages: ['znKTcCpBUe4'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Cw57JYYlL74',
      Status: 'Published',
      Description:
        'Let your light so shine! Be aglow with the Spirit,  serving the Lord,  and men shall see and rejoice in your light. Pastor Chris expounds in this video on how you can become the expression of God’s glory as you walk in the consciousness that you are the light of the World. Be inspired!',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0d77ba8f-0b15-4c60-bc56-490da233a358/the-month-of-the-shining-light.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'August 2018 - The Month of Reflection',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d44c8060-ea6b-4f6d-9c3e-ba3cb1a57bc4/August-2018-the-month-of-reflection.JPG',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: '-Ny5SAyR5co',
          Messages: ['QfbQELcqZFE'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'cr-cBKQ_tBE',
      Status: 'Published',
      Description:
        'August is the month of Reflection. In this message by Pastor Chris,  the Spirit of God guides you to review the things that the Lord had instructed you on,  not letting them slip. Gain deeper insight on how to walk in God’s perfect will when you come to the point of decision in your life.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d44c8060-ea6b-4f6d-9c3e-ba3cb1a57bc4/August-2018-the-month-of-reflection.JPG',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The Man,  The Message and The Mandate',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/da268c8f-afcc-4009-847b-38a8f35cf76d/tmtmtm_pt1_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: '4pB5arUaI2I',
          Messages: ['7rq5LZt6rr8', 'j3ve5Qgj9A4', 'pBzCooq1Dk4'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['_lPFwoiyQ5I', '5wmmASGccYQ', 'wsE_ftIo9rI'],
          SeasonId: 'IHfzooMjV0w',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'PiCuR-kF254',
      Description:
        'Grasp a deeper revelation and insight into the personality,  compassion and passion of Jesus as a man,  as you listen to and meditate on this soul-stirring teaching. This message would strengthen your convictions in the efficacy and potency of the message of salvation\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/da268c8f-afcc-4009-847b-38a8f35cf76d/tmtmtm_pt1_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Pastor Chris Prays for You',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fba31d86-181c-4e39-8650-4a7d1f7ef8f9/Pastor-Chris-prays-for-you.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'tctKKs46QDw',
          Messages: ['stEhhcxHzzY'],
        },
        {
          Status: 'Published',
          Title: 'French',
          Messages: ['RVzRhKayaZo'],
          SeasonId: 'CUhgJAqLneQ',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'qIpTNlIdxWA',
      Description:
        'The words of prayer and prophecy spoken by Pastor Chris Oyakhilome in this audio excerpt will propel you to higher levels of faith and stir you to receive blessings into your life and all that concerns you.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/fba31d86-181c-4e39-8650-4a7d1f7ef8f9/Pastor-Chris-prays-for-you.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'May 2017 - The Month of Meditation',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/458b9a99-d4f3-4304-b8fc-4f0ec798ce68/May-2017-the-month-of-meditation.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['qB35BGkEhcw'],
          SeasonId: 'G_yj0sH7S1o',
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'EON2xlYADO4',
      Description:
        'The Word of the Lord for the month of May as declared to us by Pastor Chris is our month of Meditation. Watch your life grow in lips and bounds as you ponder and ruminate on the Word of God through this video.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/458b9a99-d4f3-4304-b8fc-4f0ec798ce68/May-2017-the-month-of-meditation.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0'],
      Title: 'An Understanding of the Gospel of Jesus Christ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/caee1d73-0d7d-47be-b824-e7ff4adc733a/understanding_of_the_gospel_of_jesus_christ_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          Messages: ['nIv1R0JzqQU'],
          SeasonId: 'G3LGRM6BchM',
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['2X0zzPcY-V8'],
          SeasonId: 'hq-1kQ8-MR8',
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['A0i_Iu8GS1o'],
          SeasonId: '8YxIUq-csFQ',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'kUl-3M6gUJc',
      Description:
        'The accurate knowledge of God’s Word is what makes us masters in life. We have the life and nature of God in us,  and the Holy Spirit gives us direction in all affairs of life through the Word. Learn from this teaching by Pastor Chris the place of the Holy Spirit in your understanding of the gospel of Jesus Christ.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/caee1d73-0d7d-47be-b824-e7ff4adc733a/understanding_of_the_gospel_of_jesus_christ_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'November 2017 - The Month of Exaltation',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/83360fe0-3981-4dfa-82f1-e48a484ee99b/November-2017-the-month-of-exaltation.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'IJqLxaTAL-c',
          Messages: ['qpqNqSKuGFY'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '_0d2rJXXTp8',
      Status: 'Published',
      Description:
        "Walk in the consciousness of God's plans for you this month as you step into God's heavenly seasons that bring you prosperity and favour. ",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/83360fe0-3981-4dfa-82f1-e48a484ee99b/November-2017-the-month-of-exaltation.png',
    },
    {
      Categories: ['Eg50IsZR7ww'],
      Title: 'International Music Concert',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c795e8b4-e193-4530-9e5a-10d99b7f7925/imc_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['a68vLnCEius', '_k3iKNPGsPs'],
          SeasonId: '4ohcnOTa0wU',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '8oiNbzX-nbY',
      Description:
        'It was a night of praise,  worship,  and prophecies…first of its kind in the world of music! The event was the first “International Music Concert with Pastor Chris beamed live via satellite and the internet,  to millions of people around the world,  participating from different viewing centres. Filled with the thrills of sweet heavenly renditions,  this special compilation will literally transport you to higher realms of glory as you relive the moments of blissful praise and worship by Believers’ LoveWorld All-Stars! Get ready to be enraptured into the glory of God’s divine presence,  and be inspired for true worship in this special video\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c795e8b4-e193-4530-9e5a-10d99b7f7925/imc_240.png',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: '7 Facts of the Higher Life',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9f304ee8-2dcb-42f3-bf28-64d67a898ecb/7-facts-of-the-higher-life.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'yWsKY90p56w',
          Messages: ['mBw3cTrfC54', 'eA2-twbCUTA', 'HS4vfalh8rY'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '9CajMkxk6P4',
      Description:
        'In Christ Jesus,  there’s an incorruptible kind of life that gives you a flawless edge over the systems of this world. To enjoy the full benefits and advantage of this life,  it begins with increasing your consciousness of the facts of the higher life. So,  what are these facts? In this inspiring teaching,  Pastor Chris explains what the 7 facts of the Higher Life are.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/9f304ee8-2dcb-42f3-bf28-64d67a898ecb/7-facts-of-the-higher-life.png',
    },
    {
      Categories: ['ZtPhUh6bsak'],
      Title: 'Glory in the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6cb38914-b03c-473e-a5d9-409b5c396259/Glory_in_the_Word.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'OawJhbzvKh4',
          Messages: ['X3Mu_ZqU2hs'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'Y1K8WYN-9K4',
      Status: 'Published',
      Description:
        '"Glory is the manifestation of God’s presence and beauty in the life of a Christian. God’s desire is for his children to be conscious that they have his glory with them and live in that glory.\nIn this message by Pastor Chris,  you will enrich your life in an extra-ordinary way as you understand how to constantly increase the glory of God in your life; and how to live in the consciousness of that glory.\n"',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/6cb38914-b03c-473e-a5d9-409b5c396259/Glory_in_the_Word.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Heirs of Bondage',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/299204e6-a001-4164-8b1a-33b2ab31d137/Heirs-of-Bondage.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'mY_IC63kAyc',
          Messages: ['gkhZ2WB0k0k'],
        },
        {
          Status: 'Published',
          Title: 'Spanish',
          Messages: ['Bot8pmwsqEQ'],
          SeasonId: 'dv0tv1pD13g',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'p2sfgeFjtuM',
      Description:
        'In this illustrative teaching,  Pastor Chris expounds on biblical principles that show that biological,  geographical,  emotional and spiritual associations could influence a Christian and his relationship with God.  ',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/299204e6-a001-4164-8b1a-33b2ab31d137/Heirs-of-Bondage.png',
    },
    {
      Categories: ['N5y75pdx8k0', 'RXY0Q2L91_o', 'ZtPhUh6bsak'],
      Title: '3 Kinds of Knowledge',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/3-kinds-of-knowledge.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 't1eUIHfx9bo',
          Messages: [
            'CSOWTccJZzI',
            'DjUnpFxMxME',
            'ZvOOEGuCAWw',
            'tg0cV7D5Xu0',
            'B3HM-rW_v_A',
            'Rtq_6phDPbM',
          ],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'X8zHzOOPGHg',
      Description:
        'God wants His children to know and function in the love of Christ that surpasses intellectual knowledge, which is based in intellectual activity. A complete package of the revelation knowledge of God has been delivered to your spirit through the Word. Learn from this series on 3 Kinds of Knowledge an expository on Ginosko, Eido and Epignosis. You’ll understand from this teaching how to dwell in the arena of life through the revelation knowledge of God.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/64f7e2fee067a5414a183b812a96232b/3-kinds-of-knowledge.jpg',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Would God allow the Church to go through tribulation?',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e41f5da2-ac95-4a05-8ac1-1b63aa7d08e7/q&a3-will_the_church_be_raptured-picture-3.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'su8ozKAP9xQ',
          Messages: ['nUNjp6L19bE'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'F5Pi7E2TejA',
      Status: 'Published',
      Description:
        'Learn from this video how the great tribulation would take place and what the role of the church and Israel would be during this period.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e41f5da2-ac95-4a05-8ac1-1b63aa7d08e7/q&a3-will_the_church_be_raptured-picture-3.jpg',
    },
    {
      Categories: ['sTniib-wePM', 'AocoInCV3Qk'],
      Title: 'The Benefits of Prayer',
      Thumbnail:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_BENEFITS_OF_PRAYER.jpg',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'r7OGAYs8NiE',
          Messages: ['EjrP3riwJ9I'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: '7--k9FOAT28',
      Description:
        'Functioning by the principles of our kingdom,  assures us of a glorious life in Christ. This timely message by Pastor Chris unveils ‘the Benefits of Prayer’ that propels the believer in a never-ending victory parade.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://d2dypimxw31xa2.cloudfront.net/albums/THE_BENEFITS_OF_PRAYER.jpg',
    },
    {
      Categories: ['RXY0Q2L91_o', 'N5y75pdx8k0', 'ZtPhUh6bsak'],
      Title: '7 Uplifting Facts of Life in Christ',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d80a64a2-9c78-444f-b467-8979d90596e7/7-uplifting-facts-of-life-in-christ.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'English',
          SeasonId: 'ufsvJDUR5C8',
          Messages: ['WCLLvk8p0Wk'],
        },
        {
          Status: 'Published',
          Title: 'Hindi',
          Messages: ['4aHkP_7qCHA'],
          SeasonId: 'tHS52XzjH6U',
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'ItB5iNPyWHA',
      Description:
        'The Christian life is neither an assumption nor a mystery. It is a life founded on deep and divine facts from the word of God. In this inspiring teaching,  Pastor Chris unveils 7 uplifting facts of life in Christ,  for a successful Christian life.  Embrace,  get built up and lifted as you learn what these facts are.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/d80a64a2-9c78-444f-b467-8979d90596e7/7-uplifting-facts-of-life-in-christ.png',
    },
    {
      Categories: ['N5y75pdx8k0', '4BgKISdOY9s'],
      Title: 'Working the Word',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0ede7c99-5c22-4582-9cdd-08a5c9285051/working_the_word_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'wr28niAG-TE',
          Messages: ['ukwemVejmCc'],
        },
      ],
      Author: 'Sis Tawana ',
      SeriesId: 'Vk3Dy3f14sE',
      Status: 'Published',
      Description:
        "God doesn't want you poor or broke. Discover how to remove the limitations in your life and increase your strength in difficult times.",
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/0ede7c99-5c22-4582-9cdd-08a5c9285051/working_the_word_240.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'The 3 Cardinal Gifts of God',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b8e262c1-2228-40f7-8138-f6dadf6e4918/gift-of-el.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'XDFV6UaizeY',
          Messages: ['axftM6Bt3eo', 'L4vdg4r7P1w'],
        },
        {
          Status: 'Published',
          Title: 'Volume 2',
          Messages: ['ARPiyJaBEXo', 'Lp-nYRMAoRg'],
          SeasonId: 'CQrZhEqnyDU',
        },
        {
          Status: 'Published',
          Title: 'Volume 3',
          Messages: ['1K1RGdoPrxk'],
          SeasonId: 'JWe-zDhRTOo',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: '6GaDJdPDNg0',
      Description:
        'Do you know that God has given to us eternal life; and this life is in His Son,  Jesus Christ? Anyone who has the Son of God has eternal life. Learn from this exposé by Pastor Chris how life and immortality have been brought to light through the gospel of Jesus Christ.\n',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/b8e262c1-2228-40f7-8138-f6dadf6e4918/gift-of-el.png',
    },
    {
      Categories: ['N5y75pdx8k0', '6EKZPdy84W0'],
      Title: '7 Factors for Spiritual Advancement',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e457959e-6eb6-41ac-ad7e-1b7482fb3a83/7-Factors-for-Spiritual-Advancement.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          SeasonId: 'AVqgWFD6KXc',
          Messages: ['BVZVA5w5bUk'],
        },
      ],
      Author: 'Bro Desmond',
      SeriesId: 'XQwujN-0VH4',
      Status: 'Published',
      Description:
        'Life is spiritual; and those who would win continuously in life must have the consciousness of the spiritual. Learn from this teaching by Pastor Chris 7 vital factors for advancing in life.',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/e457959e-6eb6-41ac-ad7e-1b7482fb3a83/7-Factors-for-Spiritual-Advancement.png',
    },
    {
      Categories: ['N5y75pdx8k0'],
      Title: 'Conditioning Your Spirit for Excellence',
      Thumbnail:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c1626d97-0061-438c-bade-da2f62c4b207/conditioning_your_spirit_240.png',
      Seasons: [
        {
          Status: 'Published',
          Title: 'Volume 1',
          Messages: ['RKCr3uXd8lc'],
          SeasonId: 'cBxsiWYRGtw',
        },
      ],
      Author: 'Sis Doris',
      SeriesId: 'ioFyr15kofs',
      Description:
        'Your ability to comprehend God’s word and act on it will birth excellence in you. Discover the meaning of Excellence and learn how to condition your spirit to produce an excellent character.',
      Status: 'Published',
      Captions: [],
      Cover:
        'https://dr9op7qw3y7b0.cloudfront.net/albums/c1626d97-0061-438c-bade-da2f62c4b207/conditioning_your_spirit_240.png',
    },
    {
      Title: 'The Mystery of the Seal',
      SeriesId: 'MzkiATXrr5U',
    },
    {
      Title: 'The Mysteries of the Kingodm of Heaven',
      SeriesId: 'LtXItorQeu4',
    },
        {
      Title: 'Our Calling to Glory',
      SeriesId: 'JgAGXF7HJ0s',
    },
    {
      Title: 'August 2023 – The Month of Dominion',
      SeriesId: 'xrE2hhiPXP4',
    },
    {
      Title: 'How To be A Success for Jesus Christ',
      SeriesId: 't8thifncVlU',
    },
    {
      Title: 'October 2023 – The Month of Insight',
      SeriesId: 'Zrz0l0aRr34',
    },
    {
      Title: 'November 2023 – The Month of Increase',
      SeriesId: 't9OtDNXTWMA',
    },
    {
      Title: 'December 2023 – The Month of Thanksgiving',
      SeriesId: 'WvV5eI7Unu0',
    },
        {
      Title: 'Christmas and the Endless Era of Glory',
      SeriesId: 'YxDCt2joigs',
    },
    {
      Title: 'Christmas and the Prophetic Plan',
      SeriesId: 'EyaFUXnaoCA',
    },
    {
      Title: '2024 – The Year of Redemption',
      SeriesId: 'CvzNvtmDHGY',
    },
    {
      Title: 'An Expose on the Year of Redemption',
      SeriesId: 'rKiYqwSgK5M',
    },
    {
      Title: 'Jesus The Wonderful Light',
      SeriesId: 'Oer0BqDoiDQ',
    },
    {
      Title: 'The Year of Spreading',
      SeriesId: 'pH6QqIhEfsQ',
    },
    {
      Title: 'Our Righteous Cause',
      SeriesId: 'cNdcb6n20aQ',
    },
    {
      Title: 'International Cell Leaders Conference 2021',
      SeriesId: 'mErG0bRWT8M',
    },
    {
      Title: '3 Important Truths',
      SeriesId: '3yAzKT1qi4U',
    },
  ],
};

export { seriesDb };
