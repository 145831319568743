/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { seriesDb } from '../assets/scripts/Variables';

function SearchBar({ placeholder, btn }) {
  const series = seriesDb.Series;
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = series.filter((value) => value.Title.toLowerCase().includes(searchWord.toLowerCase()));

    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && wordEntered !== '') {
      navigate(`results?search_query=${wordEntered}`);
      btn(false);
    }
  };

  return (
    <div className="w-full flex justify-right">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="flex flex-row items-center">
          <input
            autoFocus
            type="text"
            placeholder={t(placeholder)}
            className="block w-full mr-2 rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
            value={wordEntered}
            onChange={handleFilter}
            onKeyDown={handleKeyDown}
          />
          <Link
            to={`results?search_query=${wordEntered}`}
            className="text-white py-2 px-4 text-sm font-semibold rounded pcdl-pink-btn"
          >
            Search
          </Link>
        </div>
        <div className="MagnifyingGlassIcon">
          {(filteredData.length === 0 && wordEntered.length === 0) ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          ) : (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <XMarkIcon
                id="clearBtn"
                className="h-5 w-5 cursor-pointer text-gray-400"
                aria-hidden="true"
                onClick={clearInput}
              />
            </div>
          )}
        </div>
        {(wordEntered.length > 0) && (
          <div className="absolute overflow-hidden left-0 z-10 mt-2 w-full min-w-[14rem] lg:w-64 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredData.length !== 0 ? (
            filteredData.slice(0, 5).map((value) => (
              <Link
                key={value.SeriesId}
                className="cursor-pointer transition hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm"
                to={`/series/${value.SeriesId}/${value.Title.replace(
                  /[\s+-]/g,
                  '-',
                ).toLowerCase()}`}
                onClick={() => {
                  clearInput();
                  btn(false);
                }}
              >
                <p>
                  {value.Title}
                  {' '}
                </p>
              </Link>
            ))
                    ) : (
                      <p className="text-gray-900 block px-4 py-2 text-sm">
                        No Results
                      </p>
                    )}
          </div>
        )}
      </div>
    </div>
  );
}
SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  btn: PropTypes.func.isRequired,
};

export default SearchBar;
