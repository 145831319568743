/* eslint-disable react/no-danger */
import React from 'react';
import logo from '../../assets/images/pcdl-logo-1.png';

export default function PageNotFound() {
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
    nav { display: none!important; } .copyright { display: none!important; }
  }
`,
        }}
      />
      <div className="centered-axis-xy">
        <div className="pt-16 pb-12 flex flex-col bg-white dark:bg-dark">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
              <a href="/" className="inline-flex">
                <span className="sr-only">PCDL</span>
                <img className="h-20 w-auto" src={logo} alt="" />
              </a>
            </div>
            <div className="py-12">
              <div className="text-center">
                <p className="text-base font-semibold text-pcdl">404</p>
                <h1 className="mt-2 text-4xl font-bold text-gray-900 dark:text-white tracking-tight sm:text-5xl sm:tracking-tight">
                  Page not found.
                </h1>
                <p className="mt-2 text-base text-gray-500 dark:text-white">
                  Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-6">
                  <a href="/" className="transition text-base font-medium text-pcdl hover:text-sky-600">
                    Go back home
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Status
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Twitter
              </a>
            </nav>
          </footer>
        </div>
      </div>
    </div>
  );
}
