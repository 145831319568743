const Logout = () => {
    setTimeout(() => {
        window.location.reload();
      }, 1000);
    localStorage.removeItem('pcdl.token');
    localStorage.removeItem('pcdl.user');
    localStorage.removeItem('pcdl.email');
    localStorage.removeItem('pcdl.refer');
    localStorage.removeItem('pcdl.subscription_name');
};

export default Logout;
