/* eslint-disable max-len */
import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('pcdl.token');
    try {
      const userToken = JSON.parse(tokenString);
      return userToken;
    } catch (error) {
      return error;
    }
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (!JSON.stringify(userToken.body.token)) {
      localStorage.setItem('pcdl.token', JSON.stringify(userToken.body.urspswrd));
    } else {
      localStorage.setItem('pcdl.token', JSON.stringify(userToken.body.token));
    }
    localStorage.setItem('pcdl.email', JSON.stringify(userToken.body.email));
    localStorage.setItem('pcdl.user', JSON.stringify(userToken.body));
    setToken(userToken.body.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
