/* eslint-disable max-len */
const user = JSON.parse(localStorage.getItem('pcdl.user'));

const bool = [{ name: 'Yes' }, { name: 'No' }];

const categories = [{ name: 'Yes' }, { name: 'No' }];

const statusTypes = [
  { name: 'Published' },
  { name: 'Draft' },
  { name: 'Unpublished' },
];

const frequency = [
  { name: 'One Month', count: 1 },
  { name: 'Three Months', count: 3 },
  { name: 'Six Months', count: 6 },
  { name: '1 Year', count: 12 },
];

const titleTypes = [
  { name: 'Brother' },
  { name: 'Sister' },
  { name: 'Pastor' },
  { name: 'Deacon' },
  { name: 'Deaconess' },
];

const seriesTypes = [{ name: 'Series' }, { name: 'Single' }];

const captionLanguages = [
  { id: 'English', name: 'English', label: 'English' },
  { id: 'French', name: 'French', label: 'French' },
  { id: 'German', name: 'German', label: 'German' },
];

const categoryTypes = [
  { id: 'RXY0Q2L91_o', label: 'Holy Spirit' },
  { id: 'N5y75pdx8k0', label: 'Christian Living' },
  { id: 'ZtPhUh6bsak', label: 'Faith' },
  { id: '6EKZPdy84W0', label: 'Health And Healing' },
  { id: 'sTniib-wePM', label: 'Prayer' },
  { id: '4BgKISdOY9s', label: 'Prosperity And Finance' },
  { id: 'sE72qV3sqJ0', label: 'Church Growth' },
  { id: 'Vk_RSCd3w10', label: 'Evangelism' },
  { id: 'Vk_RSCd3w10', label: 'Soul Winning' },
  { id: 'Eg50IsZR7ww', label: 'Music' },
  { id: 'PmBvGLyCfAo', label: 'Classics' },
  { id: '3fQ2KLtWwpY', label: 'Questions And Answers' },
  { id: 'iplfLPC2S7E', label: 'Freebies' },
  { id: 'dN2-ROtjSUM', label: 'Audiobooks' },
  { id: 'yB4dKm3LNec', label: 'Your LoveWorld Specials' },
  { id: 'ppTyq4rpOoY', label: 'Chronicles Of Prophecy' },
  { id: 'AocoInCV3Qk', label: 'Message of the Week' },
];

const tierTypes = {
  wordatwork_classic: {
    name: 'Gold Classic',
    nigeria: 10000,
    international: 6.99,
    id: 'b531bcab8e63570bd72c',
    recurringId: 'price_1NNvR2HHbk1CldHuni6EnPNl',
    id_tier: 'tier_gold_classic',
    type: 'goldc',
    prices: [
      {
        price: 6.99,
        id: 'price_1NNvDaHHbk1CldHuZXpn6YX4',
        title: '1 Month + 30 Days',
        days: 60,
      },
      {
        price: 20.97,
        id: 'price_1NNvLCHHbk1CldHuPflv6ecO',
        title: '3 Months + 90 Days',
        days: 180,
      },
      {
        price: 41.94,
        id: 'price_1NNvMsHHbk1CldHu7i8gieHr',
        title: '6 Months + 180 Days',
        days: 360,
      },
      {
        price: 83.99,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '12 Months + 365 Days',
        days: 730,
      },
    ],
  },
  wordatwork_star: {
    name: 'Gold Star',
    nigeria: 10000,
    international: 14.99,
    id: 'b531bcab8e63570bd72c',
    recurringId: 'price_1NNvR2HHbk1CldHuni6EnPNl',
    id_tier: 'tier_gold_star',
    type: 'golds',
    prices: [
      {
        price: 7,
        id: 'price_1NNvDaHHbk1CldHuZXpn6YX4',
        title: '1 Month',
        days: 30,
      },
      {
        price: 21,
        id: 'price_1NNvLCHHbk1CldHuPflv6ecO',
        title: '3 Months',
        days: 90,
      },
      {
        price: 42,
        id: 'price_1NNvMsHHbk1CldHu7i8gieHr',
        title: '6 Months',
        days: 180,
      },
      {
        price: 84,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '12 Months',
        days: 365,
      },
      {
        price: 126,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '18 Months',
        days: 548,
      },
      {
        price: 168,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '24 Months',
        days: 731,
      },
    ],
  },
  ylws: {
    name: 'Gold Star',
    nigeria: 10000,
    international: 14.99,
    id: 'b531bcab8e63570bd72c',
    recurringId: 'price_1NNvR2HHbk1CldHuni6EnPNl',
    id_tier: 'tier_gold_star',
    type: 'golds',
    prices: [
      {
        price: 17.97,
        id: 'price_1NNvDaHHbk1CldHuZXpn6YX4',
        title: 'Gold',
        tier: 'gold',
        days: 30,
      },
      {
        price: 20.97,
        id: 'price_1NNvLCHHbk1CldHuPflv6ecO',
        title: 'Gold Classic',
        tier: 'goldc',
        days: 30,
      },
      {
        price: 26.97,
        id: 'price_1NNvMsHHbk1CldHu7i8gieHr',
        title: 'Gold Elite',
        tier: 'golde',
        days: 30,
      },
      {
        price: 44.97,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: 'Gold Star',
        tier: 'golds',
        days: 30,
      },
    ],
  },
  ippc: {
    name: 'Gold Star',
    nigeria: 10000,
    international: 14.99,
    id: 'b531bcab8e63570bd72c',
    recurringId: 'price_1NNvR2HHbk1CldHuni6EnPNl',
    id_tier: 'tier_gold_star',
    type: 'golds',
    prices: [
      {
        price: 7,
        id: 'price_1NNvDaHHbk1CldHuZXpn6YX4',
        title: '1 Month',
        days: 30,
      },
      {
        price: 21,
        id: 'price_1NNvLCHHbk1CldHuPflv6ecO',
        title: '3 Months',
        days: 90,
      },
      {
        price: 42,
        id: 'price_1NNvMsHHbk1CldHu7i8gieHr',
        title: '6 Months',
        days: 180,
      },
      {
        price: 84,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '12 Months',
        days: 365,
      },
      {
        price: 126,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '18 Months',
        days: 548,
      },
      {
        price: 168,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '24 Months',
        days: 731,
      },
    ],
  },
  tier_gold: {
    name: 'Gold',
    nigeria: 1000,
    international: 5.99,
    id: 'd0be5bf6d6bcda3fb901',
    recurringId: 'price_1NNvCNHHbk1CldHuZnJcVi6b',
    id_tier: 'tier_gold',
    type: 'gold',
    prices: [
      {
        price: 5.99,
        id: 'price_1NNeTNHHbk1CldHu4kcI8tSj',
        title: 'One Month',
        days: 30,
      },
      {
        price: 17.97,
        id: 'price_1NNezYHHbk1CldHuxBTLDqiL',
        title: 'Three Months',
        days: 90,
      },
      {
        price: 35.94,
        id: 'price_1NNf0aHHbk1CldHuYtlmiltQ',
        title: 'Six Months',
        days: 180,
      },
      {
        price: 71.99,
        id: 'price_1NNf0wHHbk1CldHuLju7FahT',
        title: '1 Year',
        days: 365,
      },
    ],
  },
  tier_gold_classic: {
    name: 'Gold Classic',
    nigeria: 2000,
    international: 6.99,
    id: '8d595999aca82e53e618',
    recurringId: 'price_1NNfhAHHbk1CldHupw0vb1tr',
    id_tier: 'tier_gold_classic',
    type: 'goldc',
    prices: [
      {
        price: 6.99,
        id: 'price_1NNfh9HHbk1CldHuz5li0hmt',
        title: 'One Month',
        days: 30,
      },
      {
        price: 20.97,
        id: 'price_1NNfh9HHbk1CldHuM0ksiEnx',
        title: 'Three Months',
        days: 90,
      },
      {
        price: 41.94,
        id: 'price_1NNfh9HHbk1CldHugYqXlbCW',
        title: 'Six Months',
        days: 180,
      },
      {
        price: 83.99,
        id: 'price_1NNfh9HHbk1CldHunWXIJgFz',
        title: '1 Year',
        days: 365,
      },
    ],
  },
  tier_gold_elite: {
    name: 'Gold Elite',
    nigeria: 5000,
    international: 8.99,
    id: 'd507a070364308d03d2d',
    recurringId: 'price_1NNvJRHHbk1CldHu8Ju5fdkl',
    id_tier: 'tier_gold_elite',
    type: 'golde',
    prices: [
      {
        price: 8.99,
        id: 'price_1NNvD8HHbk1CldHuyd5ICR9d',
        title: 'One Month',
        days: 30,
      },
      {
        price: 26.97,
        id: 'price_1NNvGhHHbk1CldHuR4CY16J3',
        title: 'Three Month',
        days: 90,
      },
      {
        price: 53.94,
        id: 'price_1NNvIjHHbk1CldHuu7tftoWY',
        title: 'Six Month',
        days: 180,
      },
      {
        price: 107.88,
        id: 'price_1NNvJ7HHbk1CldHuqK1iSLAR',
        title: '1 Year',
        days: 365,
      },
    ],
  },
  tier_gold_star: {
    name: 'Gold Star',
    nigeria: 10000,
    international: 14.99,
    id: 'b531bcab8e63570bd72c',
    recurringId: 'price_1NNvR2HHbk1CldHuni6EnPNl',
    id_tier: 'tier_gold_star',
    type: 'golds',
    prices: [
      {
        price: 14.99,
        id: 'price_1NNvDaHHbk1CldHuZXpn6YX4',
        title: 'One Month',
        days: 30,
      },
      {
        price: 44.97,
        id: 'price_1NNvLCHHbk1CldHuPflv6ecO',
        title: 'Three Month',
        days: 90,
      },
      {
        price: 89.94,
        id: 'price_1NNvMsHHbk1CldHu7i8gieHr',
        title: 'Six Month',
        days: 180,
      },
      {
        price: 179.88,
        id: 'price_1NNvNvHHbk1CldHu842rQUnU',
        title: '1 Year',
        days: 365,
      },
    ],
  },
};

const interestTypes = [
  { id: 0, title: 'Health & Healing', img: './images/interests/14.webp' },
  { id: 1, title: 'Finance & Prosperity', img: './images/interests/0.png' },
  {
    id: 2,
    title: 'Relationship, Marriage, and Family',
    img: './images/interests/2.png',
  },
  { id: 3, title: 'Love, Sex and Dating', img: './images/interests/13.webp' },
  {
    id: 4,
    title: 'Success in Life, Business & Career',
    img: './images/interests/3.png',
  },
  {
    id: 5,
    title: 'Church Growth, Soulwinning & Evangelism',
    img: './images/interests/9.png',
  },
  {
    id: 6,
    title: 'Holy Spirit, Faith & Prayer',
    img: './images/interests/4.png',
  },
  { id: 7, title: 'Christian Living', img: './images/interests/5.png' },
  { id: 8, title: 'Mental Health', img: './images/interests/7.png' },
  {
    id: 9,
    title: 'Dealing with Grief, Fear, Stress and Anxiety',
    img: './images/interests/8.png',
  },
  {
    id: 10,
    title: 'Questions & Answers for Young Adults',
    img: './images/interests/6.png',
  },
  { id: 11, title: 'Children', img: './images/interests/10.png' },
  { id: 12, title: 'Others', img: './images/interests/12.webp' },
];

const tiers = [
  {
    name: 'Gold',
    id: 'tier_gold',
    href: '#',
    niara: 1000,
    international: 5.99,
    description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
    mostPopular: true,
  },
  {
    name: 'Gold Classic',
    id: 'tier_gold_classic',
    href: '#',
    niara: 2000,
    international: 6.99,
    description: 'Quis eleifend a tincidunt pellentesque. A tempor in sed.',
    mostPopular: false,
  },
  {
    name: 'Gold Elite',
    id: 'tier_gold_elite',
    href: '#',
    niara: 5000,
    international: 8.99,
    description:
      'Orci volutpat ut sed sed neque, dui eget. Quis tristique non.',
    mostPopular: false,
  },
  {
    name: 'Gold Star',
    id: 'tier_gold_star',
    href: '#',
    niara: 10000,
    international: 14.99,
    description:
      'Orci volutpat ut sed sed neque, dui eget. Quis tristique non.',
    mostPopular: false,
  },
];

const listSections = [
  {
    name: 'Features',
    features: [
      {
        name: 'Access to all messages',
        tiers: {
          tier_gold: true,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Enjoy Special Members only offers',
        tiers: {
          tier_gold: true,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Access to Message of the Week',
        tiers: {
          tier_gold: true,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Access to PCDL TV',
        tiers: {
          tier_gold: true,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Access to PCDL for Kids',
        tiers: {
          tier_gold: false,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Access to PCDL Everyday Solutions',
        tiers: {
          tier_gold: false,
          tier_gold_classic: true,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Complimentary Lounge Access',
        tiers: {
          tier_gold: false,
          tier_gold_classic: false,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Invitation to Special Events',
        tiers: {
          tier_gold: false,
          tier_gold_classic: false,
          tier_gold_elite: true,
          tier_gold_star: true,
        },
      },
      {
        name: 'Priority Pass & Seating at Special Events',
        tiers: {
          tier_gold: false,
          tier_gold_classic: false,
          tier_gold_elite: false,
          tier_gold_star: true,
        },
      },
      {
        name: 'Access to PCDL Search',
        tiers: {
          tier_gold: false,
          tier_gold_classic: false,
          tier_gold_elite: false,
          tier_gold_star: true,
        },
      },
    ],
  },
];

export {
  interestTypes,
  listSections,
  frequency,
  categories,
  tierTypes,
  tiers,
  user,
  statusTypes,
  seriesTypes,
  captionLanguages,
  categoryTypes,
  titleTypes,
  bool,
};
