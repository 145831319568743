import React, { useEffect, useState } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

TopBarProgress.config({
  barColors: {
    0: '#00b2ff',
    '1.0': '#00b2ff',
  },
  shadowBlur: 0,
  className: 'topbar',
});

function CustomSwitch({ children }) {
  const [progress, setProgress] = useState(false);
  const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    if (location.pathname === prevLoc) {
      setPrevLoc('');
      // thanks to ankit sahu
    }
  }, [location]);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  );
}

CustomSwitch.propTypes = {
  children: PropTypes.arrayOf(ReactRouterPropTypes.route).isRequired,
};

export default CustomSwitch;
