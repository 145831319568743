/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { interestTypes } from '../views/admin/components/Variables';

export default function Interests() {
  const itemEls = useRef({});
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  const interestList = (e, interest) => {
e.preventDefault();

if (list.includes(interest)) {
  const arr = list.filter((item) => item !== interest);
  setList(arr);
} else {
  setList([...list, interest]);
}
  };

  async function submitInterest(e, arr) {
    e.preventDefault();
    const fetchData = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_PCDLSUB_A}13`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Request-Method': 'POST',
          'X-Requested-With': 'XMLHttpRequest',
          
        },
        body: JSON.stringify({ Items: arr, PK: JSON.parse(localStorage.getItem('pcdl.user')).email }),
      });

      const data = await res.json();
    };

    if (list.length > 0) {
      fetchData().catch(console.error);
      if (JSON.parse(localStorage.getItem('pcdl.user')).subscription_name.includes('Gold')) {
        setShow(false);
      } else {
        setTimeout(() => {
        window.location.replace('/subscriptions');
      }, 2000);
      }
    } else {
      setError(true);
    }
    }

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_PCDLSUB_A}13?PK=${JSON.parse(localStorage.getItem('pcdl.user')).email}`, {
    method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'GET',
        'X-Requested-With': 'XMLHttpRequest',
        
      },
    });
    const data = await response.json();

    if (!data.Items) {
      setShow(true);
    }
  };

  if (localStorage.getItem('pcdl.user') !== null) {
  fetchData().catch(console.error);
}
    }, []);

  return (
    <div>
      {show && (
        <div className="bg-interest">
          <div className="mx-auto my-20 z-50 bg-neutral-800 max-w-6xl w-full p-8 shadow lg:rounded-lg">
            <div className="flex flex-col text-white font-medium mb-10">
              <h1 className="text-3xl mt-8 mb-4">Please Select your Areas of Interests</h1>
              <p>This helps us find the solutions that are of priority to you and provide answers to the questions of your heart</p>
            </div>
            <div className="m-auto mb-24 container text-center grid grid-cols-2 gap-4 sm:gap-4 lg:gap-8 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
              {interestTypes.map((interest) => (
                <button
                  className="flex cursor-pointer bg-neutral-700 rounded outline-cyan-800 outline-offset-2"
                  type="button"
                  onClick={(e) => { itemEls.current[interest.id].classList.toggle('interest_overlay'); interestList(e, interest.id); }}
                >
                  <div className="flex flex-col">
                    <div className="relative">
                      <img
                        className="rounded-t w-full object-cover"
                        alt={interest.title}
                        src={interest.img}
                      />
                      <div className="relative hidden" ref={(element) => itemEls.current[interest.id] = element}>
                        {' '}
                        <FontAwesomeIcon
                          className="mr-3 h-12 w-12 text-lg text-white p-4 centered-axis-xy"
                          icon={faCheck}
                        />

                      </div>
                    </div>
                    <div className="h-full flex items-center justify-center">
                      <h1 className="text-white font-semibold text-sm px-3 my-3 dark:text-white">
                        {interest.title}
                      </h1>
                    </div>
                  </div>
                </button>
            ))}
            </div>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              onClick={(e) => { submitInterest(e, list); }}
            >
              Submit
            </button>
            {error && (<span className="block text-white font-bold mt-3">Please select an interest to proceed</span>)}
          </div>
        </div>
    )}
    </div>
  );
}
