import React from 'react';

function Footer() {
  return (
    <div className="absolute bottom-0 w-full">
      <div className="copyright w-full relative z-10 bottom-0 text-center lg:text-left bg-neutral-800 drop-shadow hidden lg:block">
        <div className="text-xs text-center space-y-4 py-4 flex flex-col h-full mx-auto px-2 text-white sm:px-4">
          <div className="flex flex-row justify-center space-x-4">
            <a
              className="underline"
              target="_blank"
              href="/terms_conditions"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              className="underline"
              target="_blank"
              href="/privacy_policy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <p className="">
            © Copyright 2024 Pastor Chris Digital Library. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
